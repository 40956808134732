import React from "react"
import { Snackbar, withStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Component } from "react";
import { connect } from "react-redux";

const styles = (theme) => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
  },
  snackbarAlert: {
    padding: "0px 16px",
    color: "#fff",
    // backgroundColor: '#4c7cda'
  },
  sanckbarMessage: {
    fontSize: 12,
    opacity: 1,
  },
  snackbar: {
    marginTop: 20,
    "&.MuiSnackbar-anchorOriginBottomCenter": {
      transform: "translateX(0%) !important",
    },
  },
});

class CustomSnackbar extends Component {
  constructor(props) {
    super(props);
    this.hideSnackbar = this.hideSnackbar.bind(this);
  }

  hideSnackbar(event, reason) {
    this.props.dismissNotification();
  }

  render() {
    const { classes, display, message, title, severity } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={display}
        className={classes.snackbar}
        autoHideDuration={1500}
        onClose={this.hideSnackbar}
      >
        <Alert
          className={classes.snackbarAlert}
          variant="filled"
          severity={severity}
        >
          <span className={classes.sanckbarMessage}>{message}</span>
        </Alert>
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = Object.assign({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CustomSnackbar));
