import React from "react";
import {Grid, LinearProgress, createTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {removeTeamMember} from "getDetails/actions";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a modalLoader
import useEffectDidUpdate from "../../utils";

// opacity: 1;
// transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// position: absolute;
// left: 50%;
// top: 50%;
// transform: translate(-50%, -50%);
// width: 55rem;
// height: 28rem;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    flexDiv: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
    },
    heading: {
        fontWeight: 600,
    },
    contentDiv: {
        margin: 20,
    },
    buttonDiv: {
        display: "flex",
        margin: "4px 0 50px 0",
        justifyContent: "center",
    },
    acceptButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "#221c53",
        "&:hover": {
            background: "#fff",
            color: theme.palette.primary.main,
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    rejectButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "red",
        "&:hover": {
            background: "#fff",
            color: "red",
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    styleFont: {
        fontSize: 22,
        lineHeight: 1.4,
        fontWeight: 600,
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.heading}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center",
    },
}))(MuiDialogActions);

const getMUITheme = createTheme({
    overrides: {
        MuiPaper: {
            root: {
                height: "100%",
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: "310px !important",
            },
            paperWidthMd: {
                width: "800px",
                height: "190px",
            },
        },
    },
});

function DeleteTeamMemberDialogComponent(props) {
    const {open, handleClose, classes, memberinfo, teamInfo, history} = props;
    const dispatch = useDispatch();

    const {statusMessage, modalLoader, errormsg} = useSelector(
        (state) => state.clientData,
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            team_id: teamInfo.team_id,
            member_id: memberinfo.member_id,
        };
        dispatch(removeTeamMember(data));
    };
    const handleCancel = (event) => {
        event.preventDefault();
        handleClose();
    };
    useEffectDidUpdate(() => {
        if (statusMessage || errormsg) {
            handleClose();
            try {
                history.push(`/team-members/${teamInfo.team_id}`);
            } catch (err) {
                console.log(err);
            }
        }
    }, [statusMessage, errormsg]);

    return (
        <MuiThemeProvider theme={getMUITheme}>
            <Dialog
                onClose={handleClose}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                    width: "100%",
                    height: "100%",
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={false}
                maxWidth={"md"}
            >
                {modalLoader && <LinearProgress/>}
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Create team
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                Do you really want to delete {memberinfo.member_id} member?
                            </Typography>
                        </Grid>
                        <form onSubmit={handleSubmit} style={{width: "100%"}}>
                            <Grid
                                container
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    paddingTop: "20px",
                                }}
                            >
                                <Grid item>
                                    <Button
                                        name="acceptButton"
                                        disabled={modalLoader}
                                        className={classes.acceptButton}
                                        type={"submit"}
                                    >
                                        Accept
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        name="cancelButton"
                                        disabled={modalLoader}
                                        className={classes.rejectButton}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    {/* <CustomSnackbar
                                display={creditsAddedStatus}
                                message={creditsAddedStatus}
                                dismissNotification={handleSnakbarClose}
                                severity={'success'}
                            /> */}
                </DialogContent>

                {/* <DialogActions>

                    </DialogActions> */}
            </Dialog>
        </MuiThemeProvider>
    );
}

DeleteTeamMemberDialogComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteTeamMemberDialogComponent);
