import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  Link as LinkUI,
  MuiThemeProvider,
  Paper,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  createTheme,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { authActions } from "auth";
import { clientActions } from "getDetails";

import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import Logo from "../../../assets/logo.png";

const useStyles = makeStyles((theme) => ({
  rootLinear: {
    width: "100%",

    margin: "0 auto",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  fancyBg: {
    backgroundColor: "#edf2fe",
  },
  buttons: {
    padding: "10px 32px",
    fontFamily: "AvetaBold",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "8px",
    fontWeight: "600",
    textTransform: "capitalize",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    "&.MuiButton-containedPrimary:hover": {
      color: "#221c53",
      backgroundColor: "#fff",
      border: "1px solid #221c53",
      fontWeight: "600",
    },
  },
  homeBtn: {
    position: "absolute",
    bottom: 0,
    right: 0,
    margin: 10,
  },
  paperRoot: {
    position: "relative",
    width: "70%",
    minHeight: "80vh",
    margin: "20px 0px",
    display: "flex",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur(10px)",
    "-webkit-backdrop-filter": "blur( 10.0px )",
    borderRadius: "10px",
    zIndex: 100,
  },
  onboardRoot: {
    width: "40%",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4rem",
    marginLeft: "-4px",
  },
  tokenInput: {
    width: "100%",
    margin: "10px 0 25px",
    borderRadius: 5,
    backgroundColor: "#ffffff66",
  },
  cowinBold: {
    color: "#3C4AE4",
  },
  resendButton: {
    marginLeft: "16px",

    "& .MuiButton-root": {
      "& ..Mui-disabled": {
        color: "#221c53",
      },
    },
  },
  linearGradient: {
    width: "100%",
    position: "absolute",
    height: "100%",
  },
  whiteColor: {
    color: "#000",
  },
  leftBox: {
    position: "relative",
    background: theme.palette.primary.light,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  listRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#dbeffc",
    position: "relative", // overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  stepperLabel: {
    "& .MuiTypography-root": {
      fontWeight: "600",
    },
  },
}));

const theme = createTheme({
  palette: {
    action: {
      disabled: "#221c53",
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        padding: "6px 0",
      },
    },
  },
});

function Login(props) {
  const { getProductList } = props;
  const classes = useStyles();
  const loginInput = useRef(null);
  const otpInput = useRef(null);
  const emailInput = useRef(null);
  const nameInput = useRef(null);
  const referralInput = useRef(null);
  const linkedinInput = useRef(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [referralEntered, setReferralEntered] = useState(false);
  const [number, setNumber] = useState(30);
  const [resendNumber, setResendNumber] = useState(30);
  const [otpTimer, setOtpTimer] = useState(false);
  const search = useLocation().search;

   console.log("search",search)
  const [phoneNo, setPhoneNumber] = useState("");

  console.log("phoneNophoneNo",phoneNo);
  
  // const name = new URLSearchParams(search).get('plan_id')
  // props.getPlanId(name)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [successMessage, setSuccessMessage] = useState();

  const history = useHistory();

  useEffect(() => {
    console.log(props.errorMsg);
    setError(props.errorMsg);
    setShow(false);
    setOtpTimer(false);
    clearInterval(counterId);
    setResendNumber(30);
  }, [props.errorMsg]);

  // useEffect(() => {
  //   if (props.onboardRequired && props.otpVerified) {
  //     history.push('get-onboard')
  //   }
  // }, [props.onboardRequired, props.otpVerified])

  let resendCounter;
  let otpCounter;

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    const email = props.email ? "" : emailInput.current.value;
    const name = nameInput.current.value;
    const referral = referralInput.current.value;
    console.log("Email", email, name);
    if (email && name) {
      if (referral) {
        if (!referralEntered) {
          setReferralEntered(true);
        } else {
          const linkedinUrl = linkedinInput.current.value;
          const data = {
            name: name,
            email: email,
            linkedin_url: referral,
            referral_code: linkedinUrl,
          };
          props.submitDetails(data);
        }
      } else {
        const data = {
          name: name,
          email: email,
        };
        props.submitDetails(data);
      }
    } else if (!email.match(emailRegex)) {
      setError("Please enter your email");
    } else {
      setError("Please enter your name");
    }
  };

  const handleSendOTP = async () => {
    const { sendOtp, clearOtp } = props;

    console.log("Phone Number", phoneNo);
    
    // const validPhone = Number(inp) && inp.length === 10 ? true : false
    // const validPhone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(inp) || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(inp)
    const mobileValidationRegex =
      // eslint-disable-next-line no-useless-escape
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    const isValidMobile = mobileValidationRegex.test(phoneNo);

    if (!isValidMobile) {
      return setError("Invalid mobile number.");
    }

    console.log(phoneNo, "phone number");

    await sendOtp(phoneNo);

    setShow(true);
    setOtpTimer(true);
    return;
  };

  const handleResendOTP = async (resend) => {
    //use cognito for resend otp
    props.clearOtp();
    await props.sendOtp(phoneNo);
    setOtpTimer(true);

    return;
  };
  const [counterId, setCounterId] = useState();

  const handleSubmitOTP = async () => {
    const { submitOtp } = props;
    const otp = otpInput.current.value;

    if (otp.length !== 4) return setError("Invalid OTP");

    await submitOtp({
      otp,
      otpClientID: props.otpClientID,
    });

    return;
  };

  function startTimer() {
    resendCounter = setInterval(() => {
      setResendNumber((prevState) =>
        prevState <= 10 ? `0${prevState - 1}` : prevState - 1,
      );

      setCounterId(resendCounter);
    }, 1000);
  }

  useEffect(() => {
    if (props.otpSent) {
      console.log("OtpSent", props.otpSent);
      setSuccessMessage("OTP has been sent to your registerd mobile number.");
      startTimer();
      // setOtpTimer(true)
    }
  }, [props.otpSent]);

  useEffect(() => {
    if (resendNumber == "00") {
      console.log("resendNumber", resendNumber);
      clearInterval(counterId);
      resendCounter = 0;
      setResendNumber(30);
      setOtpTimer(false);

      // setResendNumber(30)
    }
  }, [resendNumber]);



  useEffect(() => {
    let extnPhoneNumber = new URLSearchParams(search).get("phone");

    console.log("extnPhoneNumber", extnPhoneNumber);
    
    if (extnPhoneNumber) {
      extnPhoneNumber = extnPhoneNumber.trim();
      const formattedPhoneNumber = extnPhoneNumber.startsWith('+') ? extnPhoneNumber : `+${extnPhoneNumber}`;
      setPhoneNumber(formattedPhoneNumber);
    }
    return () => {
      clearInterval(counterId);
    };
  }, []);


  

  const howToUseArr = [
    {
      heading: "1- Open a supported site",
      subheading:
        "Open any of these Surereach supported sites- Zaubacorp, Instafinancials or Tofler and search for the company you wanna look for.",
    },
    {
      heading: " 2- Find contacts in a click",
      subheading:
        "Click on the Surereach extension present on the top right of the the screen then click on the “Show details” button. ",
    },
    {
      heading: "3- Login with phone number",
      subheading:
        "Enter your phone number and click on the “Get started” button then submit the OTP sent to your number. ",
    },
    {
      heading: "4- Start fetching the details",
      subheading:
        "After submitting the OTP you can easily see all the details and start reaching out to your prospects. ",
    },
  ];

  function getSteps() {
    return [
      "Open a supported site",
      "Find contacts in a click",
      "Login with phone number",
      "Start fetching the detail",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return "Open any of these Surereach supported sites- Zaubacorp, Instafinancials or Tofler and search for the company you wanna look for.";
      case 1:
        return "Click on the Surereach extension present on the top right of the the screen then click on the “Show details” button.";
      case 2:
        return "Enter your phone number and click on the “Get started” button then submit the OTP sent to your number.";
      case 3:
        return "After submitting the OTP you can easily see all the details and start reaching out to your prospects.";
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        className={classes.fancyBg}
      >
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => {
            setError(null);
            props.clearError();
          }}
        >
          <Alert
            onClose={() => {
              setError(null);
              props.clearError();
            }}
            severity="error"
          >
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
          open={!!successMessage}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessMessage(null);

            props.clearOtp();
          }}
        >
          <Alert
            onClose={() => {
              setSuccessMessage(null);
              props.clearError();
            }}
            severity="success"
          >
            {successMessage}
          </Alert>
        </Snackbar>

        <Paper className={classes.paperRoot}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "50%", // overflowY: 'scroll',
              // height: '600px'
            }}
            // p={1}
            className={classes.leftBox}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 40,
              }}
            >
              <Typography
                className={classes.whiteColor}
                variant={"h5"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Welcome to
                <img src={Logo} style={{ padding: 4, height: "3.2rem" }} />
              </Typography>

              <br />
              <Divider
                style={{
                  height: "3px",
                  background: "#000",
                  width: "30%",
                }}
              />
              <br />
              <Typography
                style={{ fontWeight: 800, fontSize: "18px" }}
                variant={"subtitle1"}
              >
                Reach your clients instantly with Surereach
              </Typography>

              {/* <br />
              <img style={{ width: '50%' }} src={'https://scrapingpass.com/wp-content/uploads/2020/10/Asset-8@4x-1.png'} /> */}
              {/* <br /> */}

              <Typography
                style={{
                  fontWeight: 800,
                  fontSize: "20px",
                  padding: "10px 0 0 0",
                  width: "100%",
                }}
                variant={"subtitle1"}
              >
                How it Works?
              </Typography>

              <Stepper
                style={{ backgroundColor: "inherit" }}
                activeStep={activeStep}
                orientation="vertical"
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel className={classes.stepperLabel}>
                      {label}
                    </StepLabel>
                    <StepContent>
                      <Typography style={{ fontSize: "15px" }}>
                        {getStepContent(index)}
                      </Typography>
                      <div className={classes.actionsContainer}>
                        <div style={{ padding: "7px 0 0 0" }}>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>

                          {activeStep < 3 && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              style={{
                                letterSpacing: "1.2px",
                                padding: "5px 15px",
                                fontWeight: 400,
                              }}
                              className={classes.buttons}
                            >
                              {activeStep === steps.length - 1
                                ? "Finish"
                                : "Next"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>

          <Box
            width={"50%"}
            display={"flex"}
            flexWrap={"wrap"}
            padding={"0px 0px 100px 0"}
          >
            {(props.sendOtpLoader ||
              props.loader ||
              props.submitDetailsLoader) && (
              <div className={classes.rootLinear}>
                <LinearProgress />
              </div>
            )}
            <Box
              width="100%"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              py={5}
              px={4}
            >
              <Typography
                variant="h4"
                style={{
                  marginBottom: 40,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: "1",
                }}
              >
                {!props?.userDetails?.onboarded && props.otpVerified
                  ? "Let's get start with Surereach "
                  : " Sign in to Account"}
              </Typography>
              {!props.otpVerified || props.sendOtpLoader ? (
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputLabel
                    htmlFor="phoneNo"
                    style={{ fontWeight: "400", color: "#000" }}
                  >
                    Enter Phone Number
                  </InputLabel>
                  <MuiThemeProvider theme={theme}>
                    <PhoneInput
                      country={"in"}
                      id="phoneNo"
                      enableSearch={true}
                      value={phoneNo}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone) => {
                        setPhoneNumber(`+${phone}`);
                      }}
                      className={classes.tokenInput}
                    />
                    {/* <TextField
                    size='small'
                    variant='outlined'
                    id='phoneNo'
                    className={classes.tokenInput}
                    // inputRef={loginInput}
                    value={phoneNo}
                    onChange={(e) => { setPhoneNumber(e.target.value) }}
                    error={!!error}
                    placeholder={'ex:987xxxxxxx'}
                    autoComplete='on'
                    required
                  /> */}
                    <Collapse in={!!props.otpClientID} unmountOnExit>
                      <InputLabel htmlFor="otp">Enter OTP</InputLabel>
                      <TextField
                        size="small"
                        variant="outlined"
                        id="otp"
                        className={classes.tokenInput}
                        autoComplete="off"
                        inputRef={otpInput}
                        error={!!error}
                        autoFocus
                        required
                      />
                    </Collapse>
                  </MuiThemeProvider>
                  <br />
                  {!props.otpClientID ? (
                    <Grid container justifyContent="center">
                      <Button
                        variant="contained"
                        size={"large"}
                        color="primary"
                        onClick={handleSendOTP}
                        className={classes.buttons}
                        disabled={props.sendOtpLoader}
                        type="submit"
                      >
                        Proceed
                      </Button>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          size={"large"}
                          disabled={
                            props.sendOtpLoader || props.submitDetailsLoader
                          }
                          // fullWidth
                          color="primary"
                          className={classes.buttons}
                          onClick={handleSubmitOTP}
                          type="submit"
                        >
                          Enter OTP
                        </Button>
                      </Grid>
                      <Grid item>
                        <MuiThemeProvider theme={otpTimer ? theme : ""}>
                          <Button
                            variant="text"
                            size={"large"}
                            color="primary"
                            onClick={() => {
                              let resend = true;
                              handleResendOTP(resend);
                            }}
                            disabled={otpTimer}
                            className={classes.resendButton}

                            // type="submit"
                          >
                            {otpTimer ? `00:${resendNumber}` : `Resend OTP`}
                          </Button>
                        </MuiThemeProvider>
                        {/*{otpTimer &&(<CircularProgress value={resendNumber} />)}*/}
                      </Grid>
                    </Grid>
                  )}
                  <br />
                  <Grid container justifyContent="center">
                    <Typography variant="caption" style={{ color: "#444" }}>
                      <em>
                        By clicking proceed account I agree to &nbsp;
                        <LinkUI
                          href={"https://surereach.io/terms-of-service/"}
                          target={"_blank"}
                        >
                          Terms & Conditions
                        </LinkUI>
                      </em>
                    </Typography>
                  </Grid>
                </form>
              ) : (
                <>
                  {props.otpVerified && !props?.userDetails?.onboarded ? (
                    <form>
                      <MuiThemeProvider theme={theme}>
                        <InputLabel
                          htmlFor="name"
                          style={{ fontWeight: "400", color: "#000" }}
                        >
                          Enter Your Name
                        </InputLabel>
                        <TextField
                          size="small"
                          variant="outlined"
                          id="name"
                          className={classes.tokenInput}
                          inputRef={nameInput}
                          error={!!error}
                          placeholder={"Name"}
                          required
                        />
                        {!props.email && (
                          <>
                            <InputLabel htmlFor="otp">
                              Enter Email Id
                            </InputLabel>
                            <TextField
                              size="small"
                              variant="outlined"
                              id="emailId"
                              className={classes.tokenInput}
                              inputRef={emailInput}
                              type={"email"}
                              error={!!error}
                              placeholder={"ex:abc@gmail.com"}
                              // autoComplete='off'
                              required
                            />
                          </>
                        )}
                        <InputLabel
                          htmlFor="referral"
                          style={{ fontWeight: "400", color: "#000" }}
                        >
                          Enter Referral Code (Optional)
                        </InputLabel>
                        <TextField
                          size="small"
                          variant="outlined"
                          id="name"
                          className={classes.tokenInput}
                          inputRef={referralInput}
                          error={!!error}
                          placeholder={"Referral Code"}
                          // autoComplete='on'
                          required={false}
                        />
                        {referralEntered && (
                          <>
                            <InputLabel
                              htmlFor="linkedinUrl"
                              style={{ fontWeight: "400", color: "#000" }}
                            >
                              Enter Linkedin Url
                            </InputLabel>
                            <TextField
                              size="small"
                              variant="outlined"
                              id="name"
                              type={"url"}
                              className={classes.tokenInput}
                              inputRef={linkedinInput}
                              error={!!error}
                              placeholder={"Linkedin Url"}
                              // autoComplete='on'
                              required={true}
                            />
                          </>
                        )}
                      </MuiThemeProvider>
                      <br />
                      <Grid container justifyContent="center">
                        <Button
                          variant="contained"
                          size={"large"}
                          color="primary"
                          onClick={handleSubmitEmail}
                          className={classes.buttons}
                          disabled={props.submitDetailsLoader}
                          type="submit"
                        >
                          Proceed
                        </Button>
                      </Grid>
                      <br />
                      <Grid container justifyContent="center">
                        <Typography variant="caption" style={{ color: "#444" }}>
                          <em>
                            By clicking proceed account I agree to &nbsp;
                            <LinkUI
                              href={"https://surereach.io/terms-of-service/"}
                              target={"_blank"}
                            >
                              Terms & Conditions
                            </LinkUI>
                          </em>
                        </Typography>
                      </Grid>
                    </form>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  id: state.auth.id,
  loader: state.auth.loader, // googleClientId: state.auth.googleClientId,
  otpSent: state.auth.otpSent,
  otpClientID: state.auth.otpClientID,
  statusCode: state.auth.statusCode,
  otpVerified: state.auth.otpVerified,
  userDetails: state.clientData.userDetails,
  phone_number: state.auth.phone_number,
  email: state.auth.email,
  sendOtpLoader: state.auth.sendOtpLoader,
  errorMsg: state.auth.errorMsg,
  fullname: state.auth.fullname,
  submitDetailsLoader: state.auth.submitDetailsLoader,

  // clientId: state.auth.clientId
});

const mapDispatchToProps = {
  sendOtp: authActions.sendOtp,
  submitOtp: authActions.submitOtp,
  refreshToken: authActions.refreshToken,
  getPlanId: clientActions.getPlanID,
  checkOut: clientActions.checkOut,
  clearError: authActions.clearError,
  clearOtp: authActions.clearOtp,
  getDetails: authActions.getDetails,
  submitDetails: authActions.submitDetails,
  otpSubmitSuccessfully: authActions.otpSubmitSuccessfully,
  setPhoneNumber: authActions.setPhoneNumber,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
