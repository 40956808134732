import React from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    LinearProgress,
    TextField,
    createTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {reportLeadExtracted, reportLinkedinProfile} from "getDetails/actions";
import PropTypes from "prop-types";
import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a modalLoader
import useEffectDidUpdate from "views/pages/account/utils";

// opacity: 1;
// transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// position: absolute;
// left: 50%;
// top: 50%;
// transform: translate(-50%, -50%);
// width: 55rem;
// height: 28rem;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    flexDiv: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
    },
    heading: {
        fontWeight: 600,
    },
    contentDiv: {
        margin: 20,
    },
    buttonDiv: {
        display: "flex",
        margin: "4px 0 50px 0",
        justifyContent: "center",
    },
    acceptButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "#221c53",
        "&:hover": {
            background: "#fff",
            color: theme.palette.primary.main,
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    rejectButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "red",
        "&:hover": {
            background: "#fff",
            color: "red",
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    styleFont: {
        fontSize: 22,
        lineHeight: 1.4,
        fontWeight: 600,
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.heading}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center",
    },
}))(MuiDialogActions);

const getMUITheme = createTheme({
    overrides: {
        MuiPaper: {
            root: {
                height: "100%",
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: "310px !important",
            },
            paperWidthMd: {
                width: "800px",
                height: "340px",
            },
        },
    },
});

function ReportDialog(props) {
    const {open, handleClose, classes, reportData} = props;
    const dispatch = useDispatch();

    const {statusMessage, modalLoader, errormsg} = useSelector(
        (state) => state.clientData,
    );

    const reportMessageRef = useRef(null);

    const phoneCheckboxRef = useRef(null);
    const emailCheckboxRef = useRef(null);

    const [checkboxError, setcheckboxError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const phoneChecked = phoneCheckboxRef.current.checked;
        const emailChecked = emailCheckboxRef.current.checked;
        const reportMessage = reportMessageRef.current.value;

        if (!phoneChecked && !emailChecked) {
            return setcheckboxError(
                "You must select atleast one of these checkboxes.",
            );
        }

        console.log(reportData);

        const lead_id = reportData.lead_id;
        const request_id = reportData.request_id;

        if (lead_id) {
            const data = {
                lead_id: lead_id,
                report_reason: reportMessage,
                phone_reported: phoneChecked,
                email_reported: emailChecked,
            };

            dispatch(reportLeadExtracted(data));
        } else {
            const data = {
                linkedin_request_id: request_id,
                report_reason: reportMessage,
                phone_reported: phoneChecked,
                email_reported: emailChecked,
            };
            dispatch(reportLinkedinProfile(data));
        }
    };
    const handleCancel = (event) => {
        event.preventDefault();
        handleClose();
    };

    useEffectDidUpdate(() => {
        if (statusMessage || errormsg) {
            handleClose();
        }
    }, [statusMessage, errormsg]);

    return (
        <MuiThemeProvider theme={getMUITheme}>
            <Dialog
                onClose={handleClose}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                    width: "100%",
                    height: "100%",
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={false}
                maxWidth={"md"}
            >
                {modalLoader && <LinearProgress/>}
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Report
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>What do you want to report?</Typography>
                        </Grid>
                        <Grid>
                            <FormControl error={!!checkboxError}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => setcheckboxError("")}
                                            inputRef={phoneCheckboxRef}
                                        />
                                    }
                                    label="Phone Number"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={() => setcheckboxError("")}
                                            inputRef={emailCheckboxRef}
                                        />
                                    }
                                    label="Email ID"
                                />
                                <FormHelperText>{checkboxError}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <form onSubmit={handleSubmit} style={{width: "100%"}}>
                            <Grid item xs={12}>
                                <TextField
                                    variant={"outlined"}
                                    multiline
                                    maxRows={4}
                                    style={{margin: "10px 0px"}}
                                    inputRef={reportMessageRef}
                                    type={"text"}
                                    required={true}
                                    fullWidth={true}
                                    label={"Describe your problem with the given data in brief"}
                                ></TextField>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    paddingTop: "20px",
                                }}
                            >
                                <Grid item>
                                    <Button
                                        name="acceptButton"
                                        disabled={modalLoader}
                                        className={classes.acceptButton}
                                        type={"submit"}
                                    >
                                        Report
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        name="cancelButton"
                                        disabled={modalLoader}
                                        className={classes.rejectButton}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    {/* <CustomSnackbar
                                display={creditsAddedStatus}
                                message={creditsAddedStatus}
                                dismissNotification={handleSnakbarClose}
                                severity={'success'}
                            /> */}
                </DialogContent>

                {/* <DialogActions>

                    </DialogActions> */}
            </Dialog>
        </MuiThemeProvider>
    );
}

ReportDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportDialog);
