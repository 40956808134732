import { Chip, MuiThemeProvider, Tooltip } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Cached, ErrorOutline, Replay } from "@material-ui/icons";
import Cancel from "@material-ui/icons/Cancel";
import Done from "@material-ui/icons/Done";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import PropTypes from "prop-types";
import React from "react";

//styles for different buttons
const tagStyle = {
  complete: {
    color: "#ffffff",
    backgroundColor: "#2cce9e",
    borderRadius: "4px",
    // height: "30px",
  },
  reject: {
    color: "#ffffff",
    backgroundColor: "red",
    borderRadius: "4px",
    // height: "30px",
  },
  progress: {
    color: "#ffffff",
    backgroundColor: "#4e9be0",
    borderRadius: "4px",
    // height: "30px",
  },
  pending: {
    color: "#ffffff",
    backgroundColor: "#f0ac47",
    borderRadius: "4px",
    // height: "30px",
  },
  boxHidden: {
    display: "none",
  },
  notFound: {
    color: "#ffffff",
    borderRadius: "4px",
  },
};
const chipStyle = createTheme({
  overrides: {
    MuiChip: {
      root: {
        width: "140px",
      },
      label: {
        fontSize: "13px",
      },
    },
  },
});

//depending on status string return the required elements
function getTag(status, action) {
  //for safety change status to all lowercase
  // status = status.toLowerCase();

  switch (status) {
    case true:
      return {
        label: "Enabled",
        style: tagStyle.complete,
        icon: <Done style={tagStyle.complete} />,
      };
    case "processing":
      return {
        label: "Processing",
        style: tagStyle.progress,
        icon: <MoreHoriz style={tagStyle.progress} />,
      };
    case "requested":
      return {
        label: "Pending",
        style: tagStyle.pending,
        icon: <MoreHoriz style={tagStyle.pending} />,
      };
    case "email_pending":
    case "email_not_found":
    case "completed":
      return {
        label: "Completed",
        style: tagStyle.complete,
        icon: <Done style={tagStyle.complete} />,
      };
    case "rejected":
      return {
        label: "Rejected",
        style: Object.assign(tagStyle.reject),
        icon: <Cancel style={tagStyle.reject} aria-disabled={true} />,
      };
    case "phone_pending":
    case "phone_not_found":
      return {
        label: "Phone Not Found",
        style: Object.assign(tagStyle.reject),
        icon: <Cancel style={tagStyle.reject} aria-disabled={true} />,
      };
    // case 'email_pending':
    // case 'email_not_found':
    //   return {
    //     label: "Email Not Found",
    //     style: Object.assign(tagStyle.notFound),
    //     icon: <Cancel style={tagStyle.notFound} aria-disabled={true} />
    //   }
    case "not_found":
      return {
        label: "Not Found",
        style: Object.assign(tagStyle.reject),
        icon: <Cancel style={tagStyle.reject} aria-disabled={true} />,
      };
    case "success":
      return {
        label: "Success",
        style: tagStyle.complete,
        icon: <Done style={tagStyle.complete} />,
      };

    case "resolved":
      return {
        label: "Resolved",
        style: tagStyle.complete,
        icon: <Done style={tagStyle.complete} />,
      };
    //case fall for "in_progress, treat `in_progress` and 'in progress same'"
    case "in_progress":
    case "in progress":
      return {
        label: "In Progress",
        style: tagStyle.progress,
        icon: <MoreHoriz style={tagStyle.progress} />,
      };
    case "pending":
      return {
        label: "Pending", // dont delete space after pending it's a empty char
        style: tagStyle.pending,
        icon: <MoreHoriz style={tagStyle.pending} />,
      };
    case "partially_completed":
      return {
        label: "Partially Completed",
        style: tagStyle.complete,
        icon: <MoreHoriz style={tagStyle.complete} />,
      };
    case "failed":
      return {
        label: "Failed",
        style: Object.assign(tagStyle.reject),
        icon: <Cancel style={tagStyle.reject} aria-disabled={true} />,
      };
    case "action_required":
      return {
        label: "Action Required",
        style: Object.assign(tagStyle.reject),
        icon: <ErrorOutline style={tagStyle.reject} aria-disabled={true} />,
      };

    case "verification_failed":
      return {
        label: "Verification Failed",
        style: Object.assign(tagStyle.complete),
      };

    case "mca_payment_processing":
      return {
        label: "MCA Payment Processing",
        style: Object.assign(tagStyle.pending),
        icon: <Cached style={tagStyle.pending} aria-disabled={true} />,
      };
    case "mca_ticket_processing":
      return {
        label: "MCA Ticket Processing",
        style: Object.assign(tagStyle.pending),
        icon: <ErrorOutline style={tagStyle.pending} aria-disabled={true} />,
      };
    case "awaiting_retry":
      return {
        label: "Awaiting Retry",
        style: Object.assign(tagStyle.progress),
        icon: <Replay style={tagStyle.progress} aria-disabled={true} />,
      };
    case null:
      return {
        label: "Pending", // dont delete space after pending it's a empty char
        style: tagStyle.pending,
        icon: <MoreHoriz style={tagStyle.pending} />,
      };

    case false:
      return {
        label: "Disabled",
        style: tagStyle.reject,
        icon: <Cancel style={tagStyle.reject} aria-disabled={true} />,
      };

    default:
      return {
        label: "",
        style: Object.assign(tagStyle.boxHidden),
        icon: <MoreHoriz style={tagStyle.boxHidden} aria-disabled={true} />,
      };
  }
}

function StatusTag(props) {
  const { status, withIcon, className, withLabel = true, action } = props;
  const { label, style, icon } = getTag(status);
  return (
    <MuiThemeProvider theme={chipStyle}>
      <Tooltip title={label}>
        <Chip
          label={label}
          icon={withIcon ? icon : null}
          style={style}
          className={className}
        />
      </Tooltip>
    </MuiThemeProvider>
  );
}

StatusTag.defaultProps = {
  withIcon: false,
};

StatusTag.propTypes = {
  status: PropTypes.oneOf([
    "pending",
    "resolved",
    "processing",
    "mca_payment_processing",
    "verification_failed",
    "awaiting_retry",
    "paused",
    "failed",
    "action_required",
    "mca_ticket_processing",
    "partially_completed",
    "success",
    "rejected",
    "completed",
    true,
    false,
    null,
  ]),
  withIcon: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default React.memo(StatusTag);
