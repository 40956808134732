import {Grid} from "@material-ui/core";
import {CategoryScale} from "chart.js";
import ChartJS from "chart.js/auto"
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";

import {Line} from "react-chartjs-2";

// ChartJS.name;
dayjs.extend(utc);

ChartJS.register(CategoryScale);

function LineChart({apiUsage}) {
    const map = {};
    let label = [];
    let data = [];

    if (apiUsage) {
        apiUsage.forEach((data, index) => {
            const month = dayjs.utc(data.timestamp).local().format("MMM DD YYYY");
            const creditUsed = Number(data.credit_used);
            if (!(month in map)) map[month] = creditUsed; else {
                map[month] = map[month] += creditUsed;
            }
        });
        // const monthDate = dayjs(data.timestamp).format("MMM DD")
        Object.keys(map).forEach((key) => {
            if (map[key] <= 0) {
                delete map[key];
            }
        });

        const ordered = Object.keys(map)
            .sort()
            .reduce((obj, key) => {
                obj[key] = map[key];
                return obj;
            }, {});

        label = Object.keys(ordered);
        data = Object.values(ordered);
        console.log(ordered);
    }

    // console.log(data, label)

    // const testDate = new Date();
    // const testTimeStampArr = []

    // for (var i = 0; i < 5; i++) {
    //   testDate.setMonth(testDate.getMonth() + 1);
    //   testDate.setDate(testDate.getDate() + 1);
    //   testTimeStampArr.push({ created_at: String(testDate), amount: 1 + 10 + Math.floor((Math.random() * 10) + 1) })
    // }

    // console.log(testTimeStampArr)

    // let label = []
    // let data = []
    // const apiData = testTimeStampArr

    // apiData.forEach((val) => {
    //   data.push(val.amount)
    //   label.push(dayjs(val.created_at).format("MMM DD"))
    // })

    const chartData = {
        labels: label, datasets: [{
            label: "Credits", data: data, fill: false, borderColor: "rgb(75,192,192)", tension: 0.1,
        },], options: {
            title: {
                display: true, text: "No<>Pdf", fontSize: 24,
            }, plugins: {
                legend: {
                    display: false,
                },
            }, scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true, labelString: "Credits",
                    },
                },], xAxes: [{
                    scaleLabel: {
                        display: true, labelString: "Days",
                    },
                },],
            }, layout: {
                padding: {
                    left: 10, right: 0, bottom: 0, top: 0,
                },
            }, tooltips: {
                enabled: false,
            },
        },
    };
    // console.log(chartData?.options);

    return (<Grid container style={{paddingLeft: "20px"}}>
        <Line data={chartData} height={80} options={chartData.options}/>
    </Grid>);
}

export default React.memo(LineChart);
