import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import ReportIcon from "@material-ui/icons/Report";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { authActions } from "auth";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { clientActions } from "getDetails";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import StatusTag from "views/components/StatusTags";
import TextFloatingFilterComponent from "views/components/custom-floating-filter/TextFloatingFilterComponent";
import CustomSnackbar from "views/components/custom-snackbar";
import rechargeImg from "../../../../assets/recharge-img.jpg";
import CreditsExhaustedPopupComponent from "../../../components/CreditsExhaustedPopupComponent";
import BulkRequestPopupComponent from "./components/BulkRequestPopupComponent";

dayjs.extend(utc);
dayjs.extend(timezone);

// const Heading = withStyles((theme) => ({
//     root:
// }))(Box);

const styles = (theme) => ({
  heading: {
    width: "100%",
    fontSize: "25px",
  },
  tableHeading: {
    width: "100%",
    // border: "solid 1px",
    borderColor: "#babfc7",
    borderBottom: "none",
    backgroundColor: "#fff",
    height: "60px",
    padding: "0 18px 0px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamsTable: {
    margin: "25px 25px 0px",
  },
  table: {
    "& .ag-icon ": { fontFamily: "agGridAlpine !important" },
    "& .ag-theme-alpine .ag-radio-button-input-wrapper": {
      fontFamily: "agGridAlpine !important",
    },
    "& .ag-header-row": {
      background: "#fff !important",
    },
    "& .ag-header-cell-label , .ag-floating-filter-full-body": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .ag-header-cell-label > .ag-header-cell-text": {
      fontSize: "14.5px",
      fontWeight: "800",
      color: "black",
    },
    "& .ag-row .ag-cell ": {
      display: "flex",
      justifyContent: "flex-start" /* align horizontal */,
      alignItems: "center",
    },
    "& .ag-paging-row-summary-panel , .ag-paging-page-summary-panel": {
      color: "black !important",
      fontWeight: "700 !important",
    },
  },
  totalResults: {
    textTransform: "capitalize",
    fontWeight: "600",
    color: "black",
    fontSize: "19px",
  },
  button: {
    color: "#fff",
    padding: "7px 10px",
    // width: "10rem",
    fontSize: "13px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
  statusChip: {
    backgroundColor: "rgb(44, 206, 158)",
    color: "white",
    textTransform: "capitalize",
    borderRadius: "5px",
  },
  ellipsis: {
    fontSize: "13px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  boxContainer: {
    // height: '50vh',
    margin: "30px 30px 30px",
    boxShadow: "none",
  },
});

class LinkedinBulkRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      snackbarSeverity: null,
      BulkRequestPopup: false,
      creditsExhaustedPopupScreen: false,
      insufficientCredits: false,
    };
    this.gridRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.downloadLinkRef = React.createRef();
  }
  componentDidMount() {
    this.props.getLinkedinBulkRequests();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = prevState;

    if (
      !nextProps.userDetails?.team_id &&
      nextProps.userDetails?.total_credits <= 0 &&
      !prevState.insufficientCredits
    ) {
      newState.creditsExhaustedPopupScreen = true;
      newState.insufficientCredits = true;
      return newState;
    }
  }
  componentWillUnmount() {
    // this.props.setlinkedinRequests(null)
  }

  clickDownloadLink = () => {
    this.downloadLinkRef.current.href =
      this.props.linkedinBulkRequestDownloadUrl;
    this.downloadLinkRef.current.click();
    this.downloadLinkRef.current.href = "";
    this.props.setLinkedinBulkRequestDownloadUrl("");
  };

  componentDidUpdate(prevProps) {
    if (this.props.linkedinBulkRequestDownloadUrl) {
      this.clickDownloadLink();
    }
  }

  handleClose = () => {
    this.setState({
      snackbarMessage: "",
      BulkRequestPopup: false,
      creditsExhaustedPopupScreen: false,
      reportData: null,
    });
    this.props.getLinkedinBulkRequests();
  };
  ToolTipRenderer = (params) => {
    return (
      <Tooltip title={params.value} classes={this.props.classes.tooltip}>
        <Typography className={this.props.classes.ellipsis}>
          {params.value}
        </Typography>
      </Tooltip>
    );
  };
  showSnackbar = (message, severity) => {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  ReportButtonRenderer = (params) => {
    return (
      <Tooltip title={"Report Problem"}>
        <Grid container justifyContent={"center"}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              if (this?.props?.userDetails?.total_credits <= 0) {
                this.setState({
                  creditsExhaustedPopupScreen: true,
                });
              }
            }}
            disabled={this.props.loader}
          >
            <ReportIcon
              style={{ cursor: "pointer" }}
              color={this.props.loader ? "disabled" : "error"}
            />
          </IconButton>
        </Grid>
      </Tooltip>
    );
  };
  ClickToCopyRenderer = (params) => {
    const status = params.data.status;
    const field = params.colDef.field;
    return (
      <>
        {params.value ? (
          <Tooltip title={"Click to copy"} classes={this.props.classes.tooltip}>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(params.value);
                this.showSnackbar(
                  field == "phone_no"
                    ? "Phone number copied"
                    : "Email ID copied",
                  "success",
                );
              }}
            >
              {" "}
              <Typography
                className={this.props.classes.ellipsis}
                style={{ textTransform: "none" }}
              >
                {params.value}{" "}
              </Typography>
            </Button>
          </Tooltip>
        ) : (
          "-"
        )}
      </>
    );
  };
  onBtExport = () => {
    this.gridRef.current.api.exportDataAsCsv();
  };

  dateComparator = (date1, date2) => {
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  };

  DateRenderer = (params) => {
    return (
      <Typography component={"span"} style={{ fontSize: 13 }}>
        {dayjs.utc(params.value).local().format("D/MM/YYYY, ddd, h:mm A")}
      </Typography>
    );
  };
  DeleteTeamRenderer = (params) => {
    console.log(this.props.loader, "client loader");
    return (
      <Tooltip title={"Delete Team"}>
        <Grid container justifyContent={"center"}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            disabled={this.props.loader}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              color={this.props.loader ? "disabled" : "error"}
            />
          </IconButton>
        </Grid>
      </Tooltip>
    );
  };

  onCellClicked = (props) => {
    // const field = props.colDef.field
    // const cellData = props.data
    // if (field == 'reportButton' && this?.props?.userDetails?.total_credits > 0) {
    //     this.setState({ BulkRequestPopup: true, reportData: cellData })
    // }
  };

  LinkRenderer = (params) => {
    return (
      <Typography
        className={this.props.classes.ellipsis}
        component={Link}
        href={params.value}
        target={"_blank"}
      >
        {params.value}
      </Typography>
    );
  };

  StatusRenderer = (params) => {
    return (
      <Grid
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {params.value !== "completed" ? (
          <StatusTag status={params.value} />
        ) : (
          <>
            <a
              ref={this.downloadLinkRef}
              target={"_blank"}
              rel="noreferrer"
              href={this.props?.linkedinBulkRequestDownloadUrl}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              startIcon={<GetApp />}
              onClick={() => {
                this.props.getResolvedLinekedinBulkRequest({
                  client_id: params?.data?.client_id,
                });
              }}
            >
              Download
            </Button>
          </>
        )}
      </Grid>
    );
  };

  render() {
    const { classes, loader, linkedinBulkRequests, errormsg } = this.props;
    const { snackbarMessage } = this.state;

    const colDef = [
      { field: "client_id", headerName: "Request ID" },
      {
        field: "timestamp",
        headerName: "Requested on",
        sort: "desc",
        comparator: this.dateComparator,
        cellRenderer: this.DateRenderer,
      },
      { field: "filename", headerName: "File Name" },
      {
        field: "status",
        headerName: "",
        cellRenderer: this.StatusRenderer,
        minWidth: 100,
        flex: "0.5",
        floatingFilter: false,
      },
    ];

    const defaultColDef = {
      sortable: true,
      flex: 1,
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      floatingFilterComponent: TextFloatingFilterComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      suppressMenu: true,
      cellClass: "multiline",
      cellRenderer: this.ToolTipRenderer,
    };

    return (
      <React.Fragment>
        {this?.props?.userDetails?.allow_linkedin_bulk_upload ? (
          <>
            {loader && <LinearProgress />}
            {this.state.BulkRequestPopup && (
              <BulkRequestPopupComponent
                loader={loader}
                reportData={this.state.reportData}
                open={this.state.BulkRequestPopup}
                handleClose={this.handleClose}
              />
            )}
            <CreditsExhaustedPopupComponent
              open={this.state.creditsExhaustedPopupScreen}
              handleClose={this.handleClose}
            />

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Grid style={{ padding: "25px 40px 0px 25px" }}>
                <Typography className={classes.heading}>
                  Linkedin Bulk Requests
                </Typography>
              </Grid>
              <Grid style={{ padding: "25px 40px 0px 25px" }}>
                <Button
                  className={classes.button}
                  startIcon={<PublishIcon />}
                  onClick={() => {
                    this.setState({ BulkRequestPopup: true });
                  }}
                >
                  <Typography component={"span"}>
                    Submit Bulk Request
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <div className={classes.teamsTable}>
              <Grid container className={classes.tableHeading}>
                <Grid item xs={6}>
                  <Typography className={classes.totalResults}>
                    Showing{" "}
                    {linkedinBulkRequests ? linkedinBulkRequests.length : 0}{" "}
                    Results...
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button className={classes.button} onClick={this.onBtExport}>
                    <GetApp style={{ width: "1.5rem", fontSize: "1.3rem" }} />
                    <Typography component={"span"}>Download CSV</Typography>
                  </Button>
                </Grid>
              </Grid>
              <div className="ag-theme-material">
                <AgGridReact
                  ref={this.gridRef}
                  rowData={linkedinBulkRequests}
                  columnDefs={colDef}
                  defaultColDef={defaultColDef}
                  domLayout={"autoHeight"}
                  className={classes.table}
                  animateRows={true}
                  suppressDragLeaveHidesColumns={true}
                  cacheQuickFilter={true}
                  pagination={true}
                  paginationPageSize={9}
                  onCellClicked={this.onCellClicked}
                ></AgGridReact>
              </div>
            </div>
            <CustomSnackbar
              display={snackbarMessage}
              message={snackbarMessage}
              dismissNotification={() => {
                this.handleClose();
              }}
              severity={"success"}
            />

            <CustomSnackbar
              display={errormsg}
              message={errormsg}
              dismissNotification={() => {
                this.props.clearError();
              }}
              severity={"error"}
            />
            <CustomSnackbar
              display={this.props.statusMessage}
              message={this.props.statusMessage}
              dismissNotification={() => {
                this.props.setStatusMessage("");
              }}
              severity={"success"}
            />
          </>
        ) : (
          <>
            <Box component={Paper} className={classes.boxContainer}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={rechargeImg}
                    style={{
                      maxWidth: 530,
                      objectFit: "contain",
                      padding: "20px 0 40px 0",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "10px 70px 20px", textAlign: "center" }}
                >
                  <Typography
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      padding: "0 0 10px 0",
                    }}
                  >
                    Upgrade to Startup/Business plan to use Linkedin Bulk Upload
                    Feature.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={"12"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 0 30px 0px",
                  }}
                >
                  <Link
                    href={"https://surereach.io/#plan-pricing"}
                    target={"_blank"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      style={{ padding: "7px 14px", fontSize: "18px" }}
                      className={classes.button}
                    >
                      Upgrade Now
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.clientData.loader,
  linkedinBulkRequests: state.clientData.linkedinBulkRequests,
  linkedinBulkRequestDownloadUrl:
    state.clientData.linkedinBulkRequestDownloadUrl,
  statusMessage: state.clientData.statusMessage,
  errormsg: state.clientData.errormsg,
  userDetails: state.clientData.userDetails,
  userTeamDetails: state.clientData.userTeamDetails,
});

const mapDispatchToProps = {
  getLinkedinBulkRequests: clientActions.getLinkedinBulkRequests,
  getResolvedLinekedinBulkRequest:
    clientActions.getResolvedLinekedinBulkRequest,
  setLinkedinBulkRequestDownloadUrl:
    clientActions.setLinkedinBulkRequestDownloadUrl,
  // setlinkedinRequests: clientActions.setlinkedinRequests,
  setStatusMessage: clientActions.setStatusMessage,
  clearError: authActions.clearError,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LinkedinBulkRequests);
