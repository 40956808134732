import { connectRouter } from "connected-react-router";
import {
  clientsReducer,
  planPopReducer,
  planReducer,
} from "getDetails/reducer";
import { combineReducers } from "redux";
import { SIGN_OUT_SUCCESS, authReducer, modalReducer } from "./auth";
import history from "./history";

const appReducers = combineReducers({
  auth: authReducer,
  modalRed: modalReducer,
  clientData: clientsReducer,
  planPopRed: planPopReducer,
  router: connectRouter(history),
  planReducer: planReducer,
});
const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};

export default rootReducer;
