import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { authActions } from "auth";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { clientActions } from "getDetails";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import TextFloatingFilterComponent from "views/components/custom-floating-filter/TextFloatingFilterComponent";
import CustomSnackbar from "views/components/custom-snackbar";
import rechargeImg from "../../../../assets/recharge-img.jpg";
import CreateTeamDialogComponent from "./components/createTeamDialogComponent";
import DeleteTeamDialogComponent from "./components/deleteTeamDialogComponent";

dayjs.extend(utc);
dayjs.extend(timezone);

// const Heading = withStyles((theme) => ({
//     root:
// }))(Box);

const styles = (theme) => ({
  heading: {
    width: "100%",
    fontSize: "30px",
  },
  boxContainer: {
    // height: '50vh',
    margin: "30px 30px 30px",
    boxShadow: "none",
  },
  tableHeading: {
    width: "100%", // border: "solid 1px",
    borderColor: "#babfc7",
    borderBottom: "none",
    backgroundColor: "#fff",
    height: "60px",
    padding: "0 18px 0px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamsTable: {
    margin: "25px 25px 0px",
  },
  table: {
    "& .ag-icon ": { fontFamily: "agGridAlpine !important" },
    "& .ag-theme-alpine .ag-radio-button-input-wrapper": {
      fontFamily: "agGridAlpine !important",
    },
    "& .ag-header-row": {
      background: "#fff !important",
    },
    "& .ag-header-cell-label , .ag-floating-filter-full-body": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .ag-header-cell-label > .ag-header-cell-text": {
      fontSize: "14.5px",
      fontWeight: "800",
      color: "black",
    },
    "& .ag-row .ag-cell ": {
      display: "flex",
      justifyContent: "flex-start" /* align horizontal */,
      alignItems: "center",
    },
    "& .ag-paging-row-summary-panel , .ag-paging-page-summary-panel": {
      color: "black !important",
      fontWeight: "700 !important",
    },
  },
  totalResults: {
    textTransform: "capitalize",
    fontWeight: "600",
    color: "black",
    fontSize: "19px",
  },
  button: {
    color: "#fff",
    padding: "8px 10px", // width: "10rem",
    fontSize: "14px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
    "&.Mui-disabled": {
      background: "white",
    },
  },
  statusChip: {
    backgroundColor: "rgb(44, 206, 158)",
    color: "white",
    textTransform: "capitalize",
    borderRadius: "5px",
  },
  ellipsis: {
    fontSize: "13px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

class Teams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      showCreateTeamDialog: false,
      showDeleteTeamDialog: false,
      teamRowData: "",
    };
    this.gridRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.props.getAllTeams();
  }

  componentWillUnmount() {
    this.props.setAllTeams(null);
    this.props.clearError();
  }

  handleClose = () => {
    this.setState({
      snackbarMessage: "",
      showCreateTeamDialog: false,
      showDeleteTeamDialog: false,
    });
  };
  ToolTipRenderer = (params) => {
    return (
      <Tooltip title={params.value} classes={this.props.classes.tooltip}>
        <Typography className={this.props.classes.ellipsis}>
          {params.value}
        </Typography>
      </Tooltip>
    );
  };
  showSnackbar = (message, severity) => {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  StatusRenderer = (params) => {
    const status = params.data.status;
    const field = params.colDef.field;
    return (
      <Box style={{ display: "flex" }}>
        {params.value ? (
          <Tooltip title={"Click to copy"} classes={this.props.classes.tooltip}>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(params.value);
                this.showSnackbar(
                  field == "phone_no"
                    ? "Phone number copied"
                    : "Email ID copied",
                  "success",
                );
              }}
            >
              {" "}
              <Typography
                className={this.props.classes.ellipsis}
                style={{ textTransform: !params.value ? "capitalize" : "none" }}
              >
                {params.value || status.replace("_", " ")}{" "}
              </Typography>
            </Button>
          </Tooltip>
        ) : (
          <Typography
            className={this.props.classes.ellipsis}
            style={{ textTransform: !params.value ? "capitalize" : "none" }}
          >
            {params.value || status.replace("_", " ")}{" "}
          </Typography>
        )}
      </Box>
    );
  };
  ClickToCopyRenderer = (params) => {
    const status = params.data.status;
    const field = params.colDef.field;
    return (
      <>
        {params.value ? (
          <Tooltip title={"Click to copy"} classes={this.props.classes.tooltip}>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(params.value);
                this.showSnackbar(
                  field == "phone_no"
                    ? "Phone number copied"
                    : "Email ID copied",
                  "success",
                );
              }}
            >
              {" "}
              <Typography
                className={this.props.classes.ellipsis}
                style={{ textTransform: "none" }}
              >
                {params.value}{" "}
              </Typography>
            </Button>
          </Tooltip>
        ) : (
          "-"
        )}
      </>
    );
  };

  onBtExport = () => {
    this.gridRef.current.api.exportDataAsCsv();
  };

  dateComparator = (date1, date2) => {
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  };

  DateRenderer = (params) => {
    return (
      <Typography component={"span"} className={this.props.classes.ellipsis}>
        {dayjs.utc(params.value).local().format("D/MM/YYYY, ddd, h:mm A")}
      </Typography>
    );
  };
  DeleteTeamRenderer = (params) => {
    console.log(this.props.loader, "client loader");
    return (
      <Tooltip title={"Delete Team"}>
        <Grid container justifyContent={"center"}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            disabled={this.props.loader}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              color={this.props.loader ? "disabled" : "error"}
            />
          </IconButton>
        </Grid>
      </Tooltip>
    );
  };

  onCellClicked = (props) => {
    const field = props.colDef.field;
    const cellData = props.data;
    if (field == "deleteTeam") {
      this.setState({
        showDeleteTeamDialog: true,
        teamRowData: cellData,
      });
    } else {
      this.props.history.push(`/team-members/${cellData.team_id}`);
    }
    // this.setState({ showModal: true, cellData })
  };

  handleTeamShow() {
    if (
      this.props.userDetails?.permissions?.includes("surereach-teams-access")
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, loader, teamData, errormsg, userDetails } = this.props;
    const { snackbarMessage } = this.state;

    const colDef = [
      {
        field: "team_id",
        headerName: "Team ID",
        cellStyle: { textTransform: "capitalize" },
      },
      { field: "team_name", headerName: "Team Name" },
      {
        field: "created_at",
        headerName: "Created At",
        comparator: this.dateComparator,
        cellRenderer: this.DateRenderer,
      },
      {
        field: "deleteTeam",
        headerName: "Action",
        cellRenderer: this.DeleteTeamRenderer,
        flex: 0.3,
        minWidth: 20,
        floatingFilter: false,
      },
      // { field: "team_owners", headerName: "", cellRenderer: StatusRenderer },
      // { field: "team_members", headerName: "Email", cellRenderer: StatusRenderer },
      // { field: "extras", headerName: "Extras" },
    ];

    const defaultColDef = {
      sortable: true,
      flex: 1,
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      floatingFilterComponent: TextFloatingFilterComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      suppressMenu: true,
      cellClass: "multiline",
      cellRenderer: this.ToolTipRenderer,
    };

    return (
      <React.Fragment>
        {!this.handleTeamShow() ? (
          <Box component={Paper} className={classes.boxContainer}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={rechargeImg}
                  style={{
                    maxWidth: 530,
                    objectFit: "contain",
                    padding: "20px 0 40px 0",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ padding: "10px 70px 20px", textAlign: "center" }}
              >
                <Typography
                  style={{
                    fontSize: "23px",
                    fontWeight: "600",
                    padding: "0 0 10px 0",
                  }}
                >
                  Upgrade to Startup/Business plan to use Teams.
                </Typography>
              </Grid>
              <Grid
                item
                xs={"12"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 0 30px 0px",
                }}
              >
                <Link
                  href={"https://surereach.io/#plan-pricing"}
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{ padding: "7px 14px", fontSize: "18px" }}
                    className={classes.button}
                  >
                    Upgrade Now
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            {loader && <LinearProgress />}
            {this.state.showCreateTeamDialog && (
              <CreateTeamDialogComponent
                loader={loader}
                open={this.state.showCreateTeamDialog}
                handleClose={this.handleClose}
              />
            )}
            {this.state.showDeleteTeamDialog && (
              <DeleteTeamDialogComponent
                loader={loader}
                teamInfo={this.state.teamRowData}
                open={this.state.showDeleteTeamDialog}
                handleClose={this.handleClose}
              />
            )}
            <Grid container xs={12} style={{ padding: "25px 40px 0px 25px" }}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.heading}>Teams</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "3px 0 ",
                }}
              >
                <Button
                  startIcon={<AddIcon />}
                  className={classes.button}
                  onClick={() => {
                    this.setState({ showCreateTeamDialog: true });
                  }}
                  disabled={loader}
                >
                  Create Team
                </Button>
              </Grid>
            </Grid>
            <div className={classes.teamsTable}>
              <Grid container className={classes.tableHeading}>
                <Grid item xs={6}>
                  <Typography className={classes.totalResults}>
                    Showing {teamData ? teamData.length : 0} Results...
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    disabled={loader}
                    className={classes.button}
                    onClick={this.onBtExport}
                  >
                    <GetAppIcon
                      style={{ width: "1.5rem", fontSize: "1.3rem" }}
                    />
                    <Typography component={"span"}>Download CSV</Typography>
                  </Button>
                </Grid>
              </Grid>
              <div className="ag-theme-material">
                <AgGridReact
                  ref={this.gridRef}
                  rowData={teamData}
                  columnDefs={colDef}
                  defaultColDef={defaultColDef}
                  domLayout={"autoHeight"}
                  className={classes.table}
                  animateRows={true}
                  suppressDragLeaveHidesColumns={true}
                  cacheQuickFilter={true}
                  pagination={true}
                  paginationPageSize={9}
                  onCellClicked={this.onCellClicked}
                ></AgGridReact>
              </div>
            </div>
          </>
        )}
        <CustomSnackbar
          display={snackbarMessage}
          message={snackbarMessage}
          dismissNotification={() => {
            this.handleClose();
          }}
          severity={"success"}
        />

        <CustomSnackbar
          display={errormsg}
          message={
            !userDetails?.allow_teams
              ? "Your are not allowed to use Teams"
              : errormsg
          }
          dismissNotification={() => {
            this.handleClose();
            this.props.clearError();
          }}
          severity={"error"}
        />
        <CustomSnackbar
          display={this.props.statusMessage}
          message={this.props.statusMessage}
          dismissNotification={() => {
            this.props.setStatusMessage("");
          }}
          severity={"success"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.clientData.loader,
  teamData: state.clientData.teamData,
  statusMessage: state.clientData.statusMessage,
  errormsg: state.clientData.errormsg,
  userDetails: state.clientData.userDetails,
});

const mapDispatchToProps = {
  getAllTeams: clientActions.getAllTeams,
  setStatusMessage: clientActions.setStatusMessage,
  clearError: authActions.clearError,
  setAllTeams: clientActions.setAllTeams,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Teams);
