import React from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
// import {MuiThemeProvider} from "@material-ui/core/styles";
// import {CssTextField} from "../../pages/EFPO/epfoVer/epfoVer";
// import Grow from "@material-ui/core/Grow";
// import DisplayResult from "../../pages/EFPO/companysearch";
// import CompanyEmployeeDetail from "../../pages/EFPO/companydetail";

const drawerWidth = 300;

const styles = {};

const FormContainer = (props) => {
    return (
        <Paper
            variant={"elevation"}
            className={"mainApiBlock"}
            style={{
                width: `${props.width ? props.width : `65%`}`,
                margin: `${props.margin ? props.margin : "20px auto 10px auto"}`,
            }}
        >
            <Grid container item justify="center" direction="column">
                <Typography
                    variant="h6"
                    className={"mainApiBlockHeading"}
                    align="center"
                    gutterBottom
                >
                    {props.title}
                </Typography>
            </Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{margin: "0px 0px 20px 0px"}}
            >
                <Grid
                    item
                    style={{
                        width: "20%",
                        height: "4px",
                        background: "#000",
                    }}
                ></Grid>
            </Grid>
            {props.children}
        </Paper>
    );
};

export default FormContainer;
