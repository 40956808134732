import setAuthorizationToken from '../utils/axios-instance'
import {refreshTokenFailed, refreshTokenSuccessfully, signInSuccess, setPhoneNumber, refreshToken} from './actions'
import localforage from 'localforage';
import {IS_LOADER} from 'auth';

//  Verify Auth / presit login info on reload
export const initAuth = async (dispatch) => {
    // dispatch({type: IS_LOADER, payload: true})

    dispatch(refreshToken());

    // localforage.getItem("accessToken", (err, accessToken) => {
    //
    //     if (err || !accessToken) {
    //         console.log(err)
    //         dispatch({type: IS_LOADER, payload: false})
    //         dispatch(refreshTokenFailed(err?.message));
    //         return
    //     }
    //     localforage.getItem("userMobile", (err, userMobile) => {
    //         if (err || !userMobile) {
    //             dispatch({type: IS_LOADER, payload: false})
    //             dispatch(refreshTokenFailed(err?.message));
    //             return
    //         }
    //         localforage.getItem('onboarded', (err, onboarded) => {
    //             if (err || !onboarded) {
    //                 dispatch({type: IS_LOADER, payload: false})
    //                 dispatch(refreshTokenFailed(err?.message));
    //                 return
    //             }
    //             console.log(userMobile)
    //
    //             setAuthorizationToken(accessToken)
    //             dispatch(setPhoneNumber(userMobile))
    //             dispatch(signInSuccess(true))
    //             dispatch(refreshTokenSuccessfully({accessToken: accessToken}));
    //             dispatch({type: IS_LOADER, payload: false})
    //
    //         })
    //     })
    // })
}
