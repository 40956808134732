import {
    CANCEL_MODAL,
    GENERATE_TOKEN_FAILURE,
    IS_LOADER,
    MODAL_STATE,
    REVOKE_TOKEN_FAIURE,
    SET_DEMO_API_REQUEST_FAILURE,
    SET_DEMO_API_REQUEST_INITIATED,
    SET_DEMO_API_REQUEST_SUCCESS,
    SET_RESPONSE_BODY,
    CLEAR_OTP,
    CLEAR_CHECKOUT_DATA,
    SET_GOOGLE_CLIENT
} from 'auth'

import localForage from 'localforage'
import setAuthorizationToken from '../utils/axios-instance'
import {
    CALL_STARTED,
    FIRST_TIME,
    GENERATE_TOKEN,
    INIT_AUTH,
    INIT_TOKEN,
    INNER_TOGGLE_DRAWER,
    OTP_SENT_FAILED,
    OTP_SENT_TRUE,
    OTP_SUBMIT_FAILED,
    OTP_SUBMIT_SUCCESS,
    OTP_VERIFIED_TRUE,
    OUTER_TOGGLE_DRAWER,
    REFRESH_TOKEN_FAILED,
    REFRESH_TOKEN_SUCCESS,
    REVOKE_TOKEN,
    SET_EMAIL,
    SET_FULL_NAME,
    SET_PHONE_NUMBER,
    SIGN_IN_ERROR,
    SIGN_IN_SUCCESS,
    SIGN_OUT_SUCCESS,
    SUBMIT_DETAILS,
    TOKEN_LIST,
    TOKEN_LIST_FAILURE,
    CLEAR_ERROR_MESSAGE,
    SUCCESS_MODAL_STATE,
    SET_RESPONSE_FAILURE,
    SET_RESPONSE,
    HANDLE_POP_UP,
    SUBMIT_DETAILS_FAILED,
    SET_OTP_CLIENT_ID,
    IS_OTP_LOADER

} from './action-types'
import {
    getListToken,
    generateServerToken,
    revokeToken,
    setUserOnboard,
    sendOtpCall,
    submitOtpCall,
    refreshTokenCall,
    logoutCall,
} from './manager'
import {getUserDetailsCall} from 'getDetails/manager'
import {GET_USER_DETAILS, GET_USER_DETAILS_FAILURE} from 'getDetails'

export function changeModal(value) {
    return (dispatch) => {
        dispatch({
            type: MODAL_STATE, payload: value
        })
    }
}

export function handlePopUpScreen(payload) {
    return dispatch => {
        dispatch({
            type: HANDLE_POP_UP, payload
        })
    }
}

export function initAuth(user) {
    return {
        type: INIT_AUTH, payload: user
    }
}

export const setOtpClientID = (payload) => (dispatch) => {
    dispatch({
        type: SET_OTP_CLIENT_ID, payload: payload
    })
}

export const sendOtp = (mobile) => (dispatch) => {
    dispatch(callStarted(true))

    sendOtpCall({user_mobile: mobile}).then((res) => {
        const data = res.data.data
        localForage.setItem("userMobile", mobile, (err, value) => {
            dispatch(setPhoneNumber(mobile));
            dispatch(otpSentSuccessfully(true));
            dispatch(setOtpClientID(data.client_id))
        })
    }).catch((err) => {
        dispatch({
            type: OTP_SENT_FAILED, payload: err?.response?.data?.message
        })
    }).finally(() => dispatch(callStarted(false)));
};


// export function sentOtp(mobile) {
//   return (dispatch) => {
//     dispatch(callStarted())
//     userPool.signUp(mobile, getPassword(mobile), getAttributeList(), null, async (
//       err,
//       result
//     ) => {
//       if (err) {
//         console.log(err)
//         switch (err.name) {
//           case "UsernameExistsException":
//             dispatch(initCustomAuth(mobile))
//             break
//         }
//         return
//       }
//       else {
//         dispatch(initCustomAuth(mobile))
//       }
//     })
//   }
// }


export const submitOtp = (obj) => (dispatch) => {
    const {otp, otpClientID} = obj;

    dispatch({type: IS_OTP_LOADER, payload: true});
    dispatch(callStarted(true))

    submitOtpCall({
        otp: otp, client_id: otpClientID
    }).then((res) => {
        const data = res.data.data
        setAuthorizationToken(data.access_token);
        localForage.setItem(('accessToken'), data.access_token, (err, value) => {
            dispatch(getDetails());
            dispatch(otpSubmitSuccessfully({verified: true, accessToken: data.access_token}))
        })

    }).catch((err) => {
        dispatch(otpSubmitFailed(err?.response?.data?.message))
        dispatch({type: IS_OTP_LOADER, payload: false});

    })
};

// export function submitOtp(obj) {
//   return (dispatch) => {
//     dispatch(callStarted())
//     const { otp, cognitoUser } = obj
//     cognitoUser.sendCustomChallengeAnswer(otp, {
//       onSuccess: function (result) {
//         setAuthorizationToken(result.idToken.jwtToken)
//         dispatch(getDetails());
//         dispatch(otpSubmitSuccessfully({ verified: true, accessToken: result.accessToken.jwtToken, idToken: result.idToken.jwtToken }))
//         dispatch(setPhoneNumber(result.idToken.payload.phone_number));
//       },
//       onFailure: function (err) {
//         dispatch(otpSubmitFailed("Invalid OTP"))
//       },
//       customChallenge: function (challengeParameters) {
//         if (JSON.stringify(challengeParameters) === "{}") {
//           dispatch(otpSubmitFailed("Invalid OTP"))
//         }
//       }
//     });
//   }
// }

export function otpSentSuccessfully(payload) {
    return {
        type: OTP_SENT_TRUE, payload: payload
    }
}

export function otpSentFailed(payload) {
    return {
        type: OTP_SENT_FAILED, payload: payload
    }
}

export function clearCheckout() {
    return dispatch => {
        dispatch({
            type: CLEAR_CHECKOUT_DATA
        })
    }
}

export function signInError(error) {
    return {
        type: SIGN_IN_ERROR, payload: error
    }
}

export function signInSuccess(result) {
    return {
        type: SIGN_IN_SUCCESS, payload: result
    }
}

export function setPhoneNumber(phone) {
    return {
        type: SET_PHONE_NUMBER, payload: phone
    }
}

export function setEmail(phone) {
    return {
        type: SET_EMAIL, payload: phone
    }
}

export function setFullname(phone) {
    return {
        type: SET_FULL_NAME, payload: phone
    }
}


export function sendApiRequest(baseUrl, method, bearer_token) {
    return dispatch => {
        dispatch(sendDemoRequestInitiated())
        window.fetch(baseUrl, {
            method: method, headers: {
                Authorization: bearer_token
            },
        }).then(async (res) => {

            dispatch({
                type: SET_RESPONSE, payload: res
            })

            if (res.headers.get('Content-Type') === "text/html; charset=UTF-8" || res.headers.get('Content-Type') === "text/html; charset=utf-8") {
                console.log("ContentType", res.headers.get('Content-Type'))
                let data = await res.text()
                console.log("Content", data)
                dispatch({
                    type: SET_RESPONSE_BODY, payload: data
                })

            } else if (res.headers.get('Content-Type') === "text/xml; charset=UTF-8" || res.headers.get('Content-Type') === "text/xml; charset=utf-8") {
                let data = await res.text()
                let xml_data = await new window.DOMParser().parseFromString(data, "text/xml")
                console.log("Content", data)
                dispatch({
                    type: SET_RESPONSE_BODY, payload: xml_data
                })
            } else if (res.headers.get('Content-Type') === "application/json") {

                let data_json = await res.json()
                console.log("data_json", data_json)
                dispatch({
                    type: SET_RESPONSE_BODY, payload: data_json
                })
            } else {
                console.log("ContentType", res.headers.get('Content-Type'))
                let blobData = await res.blob()
                console.log("BlobData", blobData)
                dispatch({
                    type: SET_RESPONSE_BODY, payload: blobData
                })
                // setBodyData(res.body)
            }

        }).catch(err => dispatch(sendRequestFailure(err.response)))
    }

}


export function sendRequestFailure(payload) {
    return {
        type: SET_RESPONSE_FAILURE, payload
    }
}

export function sendDemoRequestInitiated() {
    return {
        type: SET_DEMO_API_REQUEST_INITIATED
    }
}

export function sendDemoRequestSuccess(payload) {
    return {
        type: SET_DEMO_API_REQUEST_SUCCESS, payload
    }
}

export function sendDemoRequestFailure(payload) {
    return {
        type: SET_DEMO_API_REQUEST_FAILURE, payload
    }
}


export function otpSubmitSuccessfully(payload) {
    return {
        type: OTP_SUBMIT_SUCCESS, payload: payload
    }
}

export function otpSubmitFailed(payload) {
    // console.log(payload);
    return {
        type: OTP_SUBMIT_FAILED, payload: payload
    }
}


export function callStarted(payload) {
    return {
        type: CALL_STARTED, payload
    }
}

export function refreshToken() {
    return dispatch => {
        dispatch({type: IS_LOADER, payload: true})
        refreshTokenCall().then((res) => {
            const data = res.data.data
            console.log(data)
            setAuthorizationToken(data.access_token)
            localForage.getItem('onboarded').then(res => {
                console.log('Res', res)
                if (res) {
                    dispatch(signInSuccess(true));
                    dispatch(refreshTokenSuccessfully({accessToken: data.access_token}));
                    localForage.setItem(('accessToken'), data.access_token, (err, value) => {
                    })
                    dispatch({type: IS_LOADER, payload: false})
                } else {
                    dispatch({type: IS_LOADER, payload: false})
                }
            })

        }).catch((err) => {
            localForage.clear()
            dispatch({type: IS_LOADER, payload: false})
            dispatch(refreshTokenFailed(err?.response?.data?.message))
        })
    }
}

export function refreshTokenSuccessfully(payload) {
    return {
        type: REFRESH_TOKEN_SUCCESS, payload: payload
    }
}

export function refreshTokenFailed(payload) {
    return {
        type: REFRESH_TOKEN_FAILED, payload: payload
    }
}

export function otpVerifiedSuccessfully(payload) {
    return {
        type: OTP_VERIFIED_TRUE, payload: payload
    }
}

export function getDetails() {
    return (dispatch) => {
        dispatch({
            type: IS_OTP_LOADER, payload: true
        });
        getUserDetailsCall().then(async (result) => {
            const data = result.data.data
            localForage.setItem('onboarded', data.onboarded, (err, value) => {
                dispatch(callStarted(false));
                if (data.onboarded) {
                    dispatch(signInSuccess({authenticated: true}));
                }

                dispatch({
                    type: GET_USER_DETAILS, payload: data
                })
                dispatch({
                    type: IS_OTP_LOADER, payload: false
                })
            })
        })
            .catch((e) => {
                dispatch(callStarted(false));
                dispatch({
                    type: GET_USER_DETAILS_FAILURE, payload: e?.response?.data?.message
                })
                dispatch({
                    type: IS_OTP_LOADER, payload: false
                })
            })
    }
}

export function submitDetails(data) {
    return (dispatch) => {
        dispatch({
            type: IS_OTP_LOADER, payload: true
        })
        setUserOnboard(data)
            .then((result) => {
                const data = result.data.data
                localForage.setItem('onboarded', true, (err, value) => {
                    dispatch({
                        type: GET_USER_DETAILS, payload: data
                    })
                    dispatch(submitDetailsSuccessfully(data))
                })
            })
            .catch((e) => {
                console.log(e)
                dispatch({
                    type: IS_OTP_LOADER, payload: false
                })
                dispatch(submitDetailsFailed(e?.response?.data?.message))
            })

    }
}


export function submitDetailsSuccessfully(payload) {
    return {
        type: SUBMIT_DETAILS, payload: payload
    }
}

export function submitDetailsFailed(payload) {
    return {
        type: SUBMIT_DETAILS_FAILED, payload: payload
    }
}


export function setFirstTime(flag) {
    return {
        type: FIRST_TIME, payload: flag
    }
}

export function signOut() {
    return (dispatch) => {
        localForage.removeItem('person').then(dispatch(signOutSuccess()))
    }
}


export function logout() {
    return (dispatch) => {
        logoutCall().finally(() => {
            localForage.clear()
            dispatch(signOutSuccess())
        })

    }
}

export function signOutSuccess() {
    return {
        type: SIGN_OUT_SUCCESS
    }
}

export function setinnerToggleDrawer(payload) {
    return {
        type: INNER_TOGGLE_DRAWER, payload
    }
}

export function setOuterToggleDrawer(payload) {
    return {
        type: OUTER_TOGGLE_DRAWER, payload
    }
}


export function initTokeStart() {
    return {
        type: INIT_TOKEN, payload: true
    }
}

export function tokenList() {
    return (dispatch) => {
        getListToken()
            .then((res) => {
                dispatch({
                    type: TOKEN_LIST, payload: res.data
                })
                dispatch({
                    type: IS_LOADER, payload: true
                })
            })
            .catch((error) => {
                dispatch({
                    type: TOKEN_LIST_FAILURE, payload: error.response
                })
                dispatch({
                    type: IS_LOADER, payload: true
                })
            })
    }
}

export function generateToken(value) {
    return (dispatch) => {
        generateServerToken(value)
            .then((res) => dispatch({
                type: GENERATE_TOKEN, payload: res.data
            }))
            .catch((error) => dispatch({type: GENERATE_TOKEN_FAILURE, payload: error.response}))
    }
}

export function callRevokeToken(value) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADER, payload: false
        })
        revokeToken(value)
            .then((res) => {
                dispatch({
                    type: REVOKE_TOKEN
                })
            })
            .catch((e) => {
                // console.log(e.response);
                dispatch({
                    type: REVOKE_TOKEN_FAIURE, payload: e.response
                })
                dispatch({
                    type: IS_LOADER, payload: true
                })
            })
    }
}

export function clearError() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ERROR_MESSAGE
        })

    }
}

export function clearOtp() {
    return dispatch => {
        dispatch({
            type: CLEAR_OTP
        })
    }
}

export function successModal(value) {
    return (dispatch) => dispatch({
        type: SUCCESS_MODAL_STATE, payload: value
    })
}

export function cancelModal(value) {
    return (dispatch) => dispatch({
        type: CANCEL_MODAL, payload: value
    })
}
