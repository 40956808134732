import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  styled,
  Table,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { authActions } from "auth";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { clientActions } from "getDetails";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "redux";
import TextFloatingFilterComponent from "views/components/custom-floating-filter/TextFloatingFilterComponent";
import CustomSnackbar from "views/components/custom-snackbar";
import AddTeamMemberDialog from "./components/addTeamMemberDialog";
import DeleteTeamMemberDialog from "./components/deleteTeamMemberDialog";
import IncrementAllottedCredits from "./components/incrementAllottedCredits";

dayjs.extend(utc);
dayjs.extend(timezone);

// const Heading = withStyles((theme) => ({
//     root:
// }))(Box);

const styles = (theme) => ({
  heading: {
    width: "100%",
    textAlign: "left",
    padding: "25px 40px 0px 25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "30px",
  },
  tableHeading: {
    width: "100%", // border: "solid 1px",
    borderColor: "#babfc7",
    borderBottom: "none",
    backgroundColor: "#fff",
    height: "60px",
    padding: "0 18px 0px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamsTable: {
    margin: "25px 25px 0px",
  },
  table: {
    "& .ag-icon ": { fontFamily: "agGridAlpine !important" },
    "& .ag-theme-alpine .ag-radio-button-input-wrapper": {
      fontFamily: "agGridAlpine !important",
    },
    "& .ag-header-row": {
      background: "#fff !important",
    },
    "& .ag-header-cell-label , .ag-floating-filter-full-body": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .ag-header-cell-label > .ag-header-cell-text": {
      fontSize: "14.5px",
      fontWeight: "800",
      color: "black",
    },
    "& .ag-row .ag-cell ": {
      display: "flex",
      justifyContent: "flex-start" /* align horizontal */,
      alignItems: "center",
    },
    "& .ag-paging-row-summary-panel , .ag-paging-page-summary-panel": {
      color: "black !important",
      fontWeight: "700 !important",
    },
  },
  totalResults: {
    textTransform: "capitalize",
    fontWeight: "600",
    color: "black",
    fontSize: "19px",
  },
  button: {
    color: "#fff",
    padding: "8px 10px", // width: "10rem",
    fontSize: "14px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      backgroundColor: "#fff !important",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
    "&.Mui-disabled": {
      background: "white",
    },
  },
  statusChip: {
    backgroundColor: "rgb(44, 206, 158)",
    color: "white",
    textTransform: "capitalize",
    borderRadius: "5px",
  },
  tableCellRowHeading: {
    color: "#999",
  },
  tableContainer: {
    margin: "25px 25px 25px",
  },
  ellipsis: {
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

class TeamMemberDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      showAddTeamMemberDialog: false,
      showRemoveTeamMemberDialog: false,
      showIncrementAllottedCreditsDialog: false,
      memberData: "",
    };
    this.gridRef = React.createRef();
    this.leadsExtractedGridRef = React.createRef();
    this.linkedinReportsGridRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const data = {
      team_id: this.props.match.params.teamID,
      member_id: this.props.match.params.memberID,
    };
    this.props.getTeamMemberDetails(data);
  }

  componentWillUnmount() {
    this.props.setCurrentTeamMemberData(null);
  }

  handleClose = () => {
    this.setState({
      snackbarMessage: "",
      showAddTeamMemberDialog: false,
      showRemoveTeamMemberDialog: false,
      showIncrementAllottedCreditsDialog: false,
    });
  };
  ToolTipRenderer = (params) => {
    return (
      <Tooltip title={params.value} classes={this.props.classes.tooltip}>
        <Typography
          style={{ textOverflow: "ellipsis", overflow: "hidden", fontSize: 14 }}
        >
          {params.value || "-"}
        </Typography>
      </Tooltip>
    );
  };
  showSnackbar = (message, severity) => {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
    });
  };

  StatusRenderer = (params) => {
    const status = params.data.status;
    const field = params.colDef.field;
    return (
      <Box style={{ display: "flex" }}>
        {params.value ? (
          <Tooltip title={"Click to copy"} classes={this.props.classes.tooltip}>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(params.value);
                this.showSnackbar(
                  field == "phone_no"
                    ? "Phone number copied"
                    : "Email ID copied",
                  "success",
                );
              }}
            >
              {" "}
              <Typography
                className={this.props.classes.ellipsis}
                style={{ textTransform: !params.value ? "capitalize" : "none" }}
              >
                {params.value || status.replace("_", " ")}{" "}
              </Typography>
            </Button>
          </Tooltip>
        ) : (
          <Typography
            className={this.props.classes.ellipsis}
            style={{ textTransform: !params.value ? "capitalize" : "none" }}
          >
            {params.value || status.replace("_", " ")}{" "}
          </Typography>
        )}
      </Box>
    );
  };
  ClickToCopyRenderer = (params) => {
    const status = params.data.status;
    const field = params.colDef.field;
    return (
      <>
        {params.value ? (
          <Tooltip title={"Click to copy"} classes={this.props.classes.tooltip}>
            <Button
              onClick={async () => {
                await navigator.clipboard.writeText(params.value);
                this.showSnackbar(
                  field == "phone_no"
                    ? "Phone number copied"
                    : "Email ID copied",
                  "success",
                );
              }}
            >
              {" "}
              <Typography
                className={this.props.classes.ellipsis}
                style={{ textTransform: "none" }}
              >
                {params.value}{" "}
              </Typography>
            </Button>
          </Tooltip>
        ) : (
          "-"
        )}
      </>
    );
  };

  onBtExport = () => {
    this.gridRef.current.api.exportDataAsCsv();
  };

  dateComparator = (date1, date2) => {
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
      return 0;
    }

    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  };

  DateRenderer = (params) => {
    return (
      <Typography component={"span"} style={{ fontSize: 14 }}>
        {dayjs.utc(params.value).local().format("D/MM/YYYY, ddd, h:mm A")}
      </Typography>
    );
  };
  DeleteTeamMemberRenderer = (params) => {
    console.log(this.props.loader, "client loader");
    return (
      <Tooltip title={"Delete Team Member"}>
        <Grid container justifyContent={"center"}>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            disabled={this.props.loader}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              color={this.props.loader ? "disabled" : "error"}
            />
          </IconButton>
        </Grid>
      </Tooltip>
    );
  };

  onCellClicked = (props) => {
    // const field = props.colDef.field
    // if (field == 'deleteMember') {
    //     const cellData = props.data
    //     this.setState({
    //         showRemoveTeamMemberDialog: true,
    //         memberData: cellData
    //     })
    // }
  };

  onLeadsExtractedBtnExport = () => {
    this.leadsExtractedGridRef.current.api.exportDataAsCsv();
  };

  onlinkedinReportsBtnExport = () => {
    this.linkedinReportsGridRef.current.api.exportDataAsCsv();
  };

  TableCellRow = styled(Grid)({
    padding: "16px",
    borderBottom: "1px solid #cecece",
    overflow: "hidden",
    fontSize: 16,
    textOverflow: "ellipsis",
  });

  render() {
    const {
      classes,
      loader,
      currentTeamData,
      currentMemberDetails,
      errormsg,
      currentMemberLinkedinRequests,
      currentMemberLeadsExtracted,
    } = this.props;
    const { snackbarMessage } = this.state;

    const leadsExtractedColDef = [
      { field: "director_data.full_name", headerName: "Name" },
      {
        field: "director_id",
        headerName: "DIN",
      },
      { field: "director_data.company_name", headerName: "Company" },
      {
        field: "director_data.designation",
        headerName: "Designation",
      },
      {
        field: "director_data.phone_no",
        headerName: "Phone",
        cellRenderer: this.ClickToCopyRenderer,
      },
      {
        field: "director_data.email",
        headerName: "Email",
        cellRenderer: this.ClickToCopyRenderer,
      },
    ];

    const linkedinReportsColDef = [
      {
        field: "name",
        headerName: "Name",
        cellStyle: { textTransform: "capitalize" },
      },
      { field: "profile_id", headerName: "Profile ID" },
      {
        field: "profile_url",
        headerName: "Profile URL",
      }, // { field: "status", headerName: "Status", cellRenderer: StatusRenderer },
      {
        field: "phone_no",
        headerName: "Phone",
        cellRenderer: this.StatusRenderer,
      },
      {
        field: "email",
        headerName: "Email",
        cellRenderer: this.StatusRenderer,
      },
    ];

    const defaultColDef = {
      sortable: true,
      flex: 1,
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      floatingFilterComponent: TextFloatingFilterComponent,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      suppressMenu: true,
      cellClass: "multiline",
      cellRenderer: this.ToolTipRenderer,
    };

    return (
      <React.Fragment>
        {loader && <LinearProgress />}
        {this.state.showAddTeamMemberDialog && (
          <AddTeamMemberDialog
            loader={loader}
            teamInfo={currentTeamData}
            open={this.state.showAddTeamMemberDialog}
            handleClose={this.handleClose}
          />
        )}
        {this.state.showRemoveTeamMemberDialog && (
          <DeleteTeamMemberDialog
            history={this.props.history}
            loader={loader}
            teamInfo={currentTeamData}
            memberinfo={currentMemberDetails}
            open={this.state.showRemoveTeamMemberDialog}
            handleClose={this.handleClose}
          />
        )}
        {this.state.showIncrementAllottedCreditsDialog && (
          <IncrementAllottedCredits
            loader={loader}
            teamInfo={currentTeamData}
            memberinfo={currentMemberDetails}
            open={this.state.showIncrementAllottedCreditsDialog}
            handleClose={this.handleClose}
          />
        )}
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ margin: "0px 0 15px 0" }}
        >
          <Typography
            className={classes.heading}
            style={{ margin: "20px 30px 0", padding: 0, width: "unset" }}
          >
            Member Details
          </Typography>
          <Button
            startIcon={<ArrowBack />}
            className={classes.button}
            onClick={() => {
              this.props.history.push(
                `/team-members/${this.props.match.params.teamID}`,
              );
            }}
            disabled={loader}
            style={{ margin: "20px 30px 0" }}
          >
            Back To Members List
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          component={Paper}
          style={{ margin: "20px 25px", overflow: "auto" }}
        >
          <Table style={{ marginTop: 1 }}>
            <Grid container>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    User ID:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.member_id}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Name:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.member_name}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Email:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.member_email}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Member Since:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.member_since
                      ? dayjs(currentMemberDetails?.member_since).format(
                          "D/MM/YYYY, ddd, h:mm A",
                        )
                      : "-"}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Email Credits Left:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.email_credits_left || "-"}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Total Email Credits
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.total_email_credits_allotted || "-"}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Credits Left:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    {" "}
                    {currentMemberDetails?.credits_left || "-"}
                  </Typography>
                </this.TableCellRow>
              </Grid>
              <Grid container xs={12} md={4}>
                <this.TableCellRow item xs={12}>
                  <Typography
                    component={"span"}
                    className={classes.tableCellRowHeading}
                  >
                    Credits Allotted:
                  </Typography>
                  <Typography component={"span"} style={{ fontWeight: 800 }}>
                    <Link
                      href={currentMemberDetails?.linkedin_url}
                      target={"_blank"}
                    >
                      {" "}
                      {currentMemberDetails?.total_credits_allotted || "-"}
                    </Link>
                  </Typography>
                </this.TableCellRow>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                style={{ width: "100%" }}
              >
                <Grid
                  item
                  style={{ display: "flex", gap: "15px", padding: "16px" }}
                >
                  <Button
                    className={classes.button}
                    disabled={loader}
                    style={{ padding: "5px 10px" }}
                    onClick={() => {
                      this.setState({
                        showIncrementAllottedCreditsDialog: true,
                      });
                    }}
                  >
                    Add Credits
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", gap: "15px", padding: "16px" }}
                >
                  <Button
                    className={classes.button}
                    disabled={loader}
                    style={{ padding: "5px 10px", backgroundColor: "red" }}
                    onClick={() => {
                      this.setState({ showRemoveTeamMemberDialog: true });
                    }}
                  >
                    Delete User From Team
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Table>
        </Grid>

        <Typography className={classes.heading}>Linkedin Requests</Typography>
        <div className={classes.tableContainer}>
          <Grid container className={classes.tableHeading}>
            <Grid item xs={6}>
              <Typography className={classes.totalResults}>
                Showing{" "}
                {currentMemberLinkedinRequests
                  ? currentMemberLinkedinRequests.length
                  : 0}{" "}
                Results...
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={classes.button}
                disabled={loader}
                onClick={this.onlinkedinReportsBtnExport}
              >
                <GetAppIcon style={{ width: "1.5rem", fontSize: "1.3rem" }} />
                <Typography component={"span"}>Download CSV</Typography>
              </Button>
            </Grid>
          </Grid>
          <div className="ag-theme-material">
            <AgGridReact
              ref={this.gridRef}
              rowData={currentMemberLinkedinRequests}
              columnDefs={linkedinReportsColDef}
              defaultColDef={defaultColDef}
              domLayout={"autoHeight"}
              className={classes.table}
              animateRows={true}
              suppressDragLeaveHidesColumns={true}
              cacheQuickFilter={true}
              pagination={true}
              paginationPageSize={9}
            ></AgGridReact>
          </div>
        </div>

        <Typography className={classes.heading}>Leads Extracted</Typography>
        <div className={classes.tableContainer}>
          <Grid container className={classes.tableHeading}>
            <Grid item xs={6}>
              <Typography className={classes.totalResults}>
                Showing{" "}
                {currentMemberLeadsExtracted
                  ? currentMemberLeadsExtracted.length
                  : 0}{" "}
                Results...
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                className={classes.button}
                disabled={loader}
                onClick={this.onLeadsExtractedBtnExport}
              >
                <GetAppIcon style={{ width: "1.5rem", fontSize: "1.3rem" }} />
                <Typography component={"span"}>Download CSV</Typography>
              </Button>
            </Grid>
          </Grid>
          <div className="ag-theme-material">
            <AgGridReact
              ref={this.leadsExtractedGridRef}
              rowData={currentMemberLeadsExtracted}
              columnDefs={leadsExtractedColDef}
              defaultColDef={defaultColDef}
              domLayout={"autoHeight"}
              className={classes.table}
              animateRows={true}
              suppressDragLeaveHidesColumns={true}
              cacheQuickFilter={true}
              pagination={true}
              paginationPageSize={30}
            ></AgGridReact>
          </div>
        </div>
        <CustomSnackbar
          display={snackbarMessage}
          message={snackbarMessage}
          dismissNotification={() => {
            this.handleClose();
          }}
          severity={"success"}
        />

        <CustomSnackbar
          display={errormsg}
          message={errormsg}
          dismissNotification={() => {
            this.handleClose();
            this.props.clearError();
          }}
          severity={"error"}
        />
        <CustomSnackbar
          display={this.props.statusMessage}
          message={this.props.statusMessage}
          dismissNotification={() => {
            this.props.setStatusMessage("");
          }}
          severity={"success"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.clientData.loader,
  currentTeamData: state.clientData.currentTeamData,
  statusMessage: state.clientData.statusMessage,
  errormsg: state.clientData.errormsg,
  currentMemberDetails: state.clientData.currentMemberDetails,
  currentMemberLinkedinRequests: state.clientData.currentMemberLinkedinRequests,
  currentMemberLeadsExtracted: state.clientData.currentMemberLeadsExtracted,
});

const mapDispatchToProps = {
  getTeamMemberDetails: clientActions.getTeamMemberDetails,
  setStatusMessage: clientActions.setStatusMessage,
  clearError: authActions.clearError,
  setCurrentTeamMemberData: clientActions.setCurrentTeamMemberData,
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TeamMemberDetails);
