import React from 'react';
import {useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";

const RequireUnauthRoute = ({
                                component: Component,
                                authenticated,
                                ...rest
                            }) => {
    const {cognitoUser} = useSelector((state) => state.auth);

    return (
        <Route
            {...rest}
            render={(props) => {
                return authenticated ? (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: {from: props.location},
                        }}
                    />
                ) : (
                    <>
                        {authenticated ? (
                            <Redirect
                                to={{
                                    pathname: "/home",
                                    state: {from: props.location},
                                }}
                            />
                        ) : (
                            <Component {...props} />
                        )}
                    </>
                );
            }}
        />
    );
};

export default RequireUnauthRoute;
