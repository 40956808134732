import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    Box, Button, InputLabel, makeStyles, TextField, Grid, MuiThemeProvider, createMuiTheme, Snackbar,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
// import { getDinDetails } from "../../../../apiCollections/action";
// import { CLEAR_ERROR, DELETE_DATA, IS_LOADER, SET_JSON_RESPONSE } from "../../../../apiCollections";
import MUIDataTable from "mui-datatables";

import CustomSnackbar from "views/components/custom-snackbar";
import axios from "axios";
import FormContainer from "views/containers/form-container/form-container";
import {withStyles} from "@material-ui/styles";
import {IS_LOADER} from "auth";
import {gridColumnsTotalWidthSelector} from "@material-ui/data-grid";
import {getPhoneEmailFromDin} from "getDetails/actions";
import {getPhoneEmailFromGST} from "getDetails/actions";
import {Alert} from "@material-ui/lab";
import {CLEAR_ERROR, GET_EMAIL_PHONE, GET_EMAIL_PHONE_GST} from "getDetails";
import ReactGA from 'react-ga'

// import DialogsModal from "../../../components/DialogComponent/DiaologModal";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%", margin: " 0 auto", "& > * + *": {
            marginTop: theme.spacing(0.3),
        },
    }, main: {
        margin: "20px auto 10px auto", width: "50%", borderRadius: "1rem", padding: "1rem",
    }, rootBox: {
        margin: "0 auto",
    }, searchBox: {
        width: "100%", padding: "10px 14px",
    }, searchBar: {
        width: "100%",
    }, resultBox: {
        width: "95%", margin: " auto", borderRadius: "3px",
    }, ul: {
        display: "flex",
        listStyle: "none",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        transition: "all .2s ease",
        "&:hover": {
            backgroundColor: " #e7e5e58f",
        },
    }, DinDetail: {
        width: "100%", margin: "1px auto", padding: "4px 8px",
    }, employeeBox: {
        width: "90%", margin: "10px auto",
    }, paper: {
        padding: "1rem", width: "80%", margin: "0 auto",
    }, input: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#999",
            }, "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
            },
        },
    }, formBox: {
        width: "94%", backgroundColor: "#fff", justifyContent: "center", margin: "0 auto",
    }, noBorder: {
        border: "none",
    }, text: {
        textTransform: "capitalize",
    }, box: {
        padding: "16px",
    }, heading: {
        fontWeight: "600",
    }, tokenInput: {
        width: "100%", margin: "10px 0 ", borderRadius: 5, backgroundColor: "#ffffff66",
    }, tableContainer: {
        width: "90%", margin: "20px auto ",
    },
}));
const theme = createMuiTheme({
    overrides: {
        MuiTypography: {
            h6: {
                // fontFamily: "'Baloo Chettan 2', cursive",
                fontWeight: "500", fontSize: "3rem", color: "#111",
            },
        }, MuiFormControl: {
            root: {
                background: "#fff",
            },
        }, MuiButton: {
            contained: {
                height: "40px", width: "17%", backgroundColor: "rgb(63, 81, 181) ", color: "#fff", "&:hover": {
                    backgroundColor: "rgb(63, 81, 181)",
                },
            },
        },
    },
});

function LinearIndeterminate() {
    const classes = useStyles();
    return (<div className={classes.root}>
        <LinearProgress/>
    </div>);
}

function GSTDetail() {
    const SubmitButton = withStyles((theme) => ({
        root: {
            color: "#fff",
            padding: "6px 6px 6px 0",
            width: "10rem",
            fontSize: "14px",
            boxShadow: " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            fontWeight: "800",
            borderRadius: "8px",
            letterSpacing: "1px",
            textTransform: "capitalize",
            backgroundColor: "#221c53",
            "&:hover": {
                background: "#fff", color: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
                opacity: 0.2, zIndex: -1,
            },
        },
    }))(Button);

    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiPaper: {
                root: {
                    width: "90%", margin: "2rem auto",
                },
            }, MUIDataTableToolbar: {
                actions: {
                    // display: "none",
                },
            }, MuiInputBase: {
                formControl: {
                    "&:hover": {
                        outline: "none",
                    },
                },
            }, MuiTableCell: {
                body: {
                    textAlign: "center",
                },
            }, MUIDataTableHeadCell: {
                data: {
                    fontWeight: "600",
                }, contentWrapper: {
                    justifyContent: "center",
                },
            },
        },
    });

    const {loader} = useSelector((state) => state.clientData)// useSelector((state) => state.loaderRed);
    const {emailPhoneDetails, errormsg, emailPhoneDetailsGST, errormsgst} = useSelector((state) => state.planReducer)
    console.log("errormsgGst", errormsgst);

    const dispatch = useDispatch();
    const [data, showData] = useState(false);
    const [gstNumber, setgstNumber] = useState("");
    const [dinDetail, setDinDetail] = useState({})

    const columns = ["Email", "GST", "Mobile Number", "Business Name"];
    const rows = [[dinDetail?.email, dinDetail?.gstin, dinDetail?.mobile_number, dinDetail?.business_name]];

    const [openModal, setOpenModal] = useState(false);
    const [errorMesg, setErrorMesg] = useState("");
    const [dinError, showdinError] = useState(false);
    const [checked, setCheck] = useState(false);
    const classes = useStyles();

    const handleChange = (e) => {
        let value = e?.target?.value;
        setgstNumber(value);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        showdinError('');
        if (!gstNumber || gstNumber?.length !== 15) {
            showdinError('Please enter valid GST Number');
        } else {
            const form = new FormData()
            const data = {
                'gstin': gstNumber
            }
            dispatch(getPhoneEmailFromGST(data))
        }
    };

    const handleClose = () => {
        setErrorMesg(null);
        dispatch({
            type: CLEAR_ERROR
        })
    };

    useEffect(() => {
        setErrorMesg(errormsgst);
    }, [errormsgst]);

    useEffect(() => {
        // if (emailPhoneDetailsGST) {
        //   emailPhoneDetailsGST.present_address = emailPhoneDetailsGST?.present_address?.replaceAll("$", ', ')
        // }
        setDinDetail(emailPhoneDetailsGST)
        showData(emailPhoneDetailsGST)
    }, [emailPhoneDetailsGST])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search)
        return () => {
            dispatch({type: GET_EMAIL_PHONE_GST})
            setDinDetail({});
        }
    }, [])

    return (<Box component="main">
        {loader && (<LinearProgress/>)}
        <Grid container xs={12} className={classes.rootBox}>
            <FormContainer title={" GST Detail"}>
                <form
                    onSubmit={handleSubmit}
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0rem 1rem",
                    }}
                >
                    <Grid container spacing={2} style={{margin: 0}}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="ID Number" className={classes.label}>GST Number</InputLabel>
                            <TextField
                                required
                                error={!!dinError}
                                variant="outlined"
                                helperText={dinError}
                                className={classes.tokenInput}
                                name="gstNumber"
                                size="small"
                                value={gstNumber}
                                placeholder={"ex: 07ABCCS0473RXXX"}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 16
                                }}
                            />
                        </Grid>
                        <Grid container justifyContent={"center"} spacing={2} style={{margin: 0}}>
                            <Grid item>
                                <SubmitButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={!!loader}
                                    style={{marginLeft: "1rem"}}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </SubmitButton>
                            </Grid>
                            {/* <Grid item>
                                    <Button
                                        variant={"contained"} color={"primary"}
                                        // disabled={Object.keys(jsonResponseBody).length === 0 || !loader}
                                        onClick={() => setOpenModal(true)}>
                                        Show JSON Response
                                    </Button>
                                </Grid> */}
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </Grid>
        {data && (<>
            {dinDetail ? (// <ResultContainer title={"Company Detail"}>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={" GST Details"}
                        data={rows}
                        columns={columns}
                        options={{
                            selectableRows: false,
                            print: false,
                            download: false,
                            viewColumns: false,
                            filter: false,
                            search: false
                        }}
                    ></MUIDataTable>
                </MuiThemeProvider>
            ) : null}
        </>)}


        <CustomSnackbar
            display={errorMesg}
            message={errorMesg}
            dismissNotification={handleClose}
            severity="error"
        />

    </Box>);
}

export default GSTDetail
