import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core";
import { Clear, Info } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 4, 1, 4),

    textTransform: "none",
    fontSize: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  darkText: {
    color: theme.palette.grey[700],
    padding: theme.spacing(1, 0, 1, 0),
  },

  subscribed: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: 3,
  },
  unsubscribe: {
    fontSize: "20px",
    fontWeight: "bold",
    paddingTop: "16px",
    lineHeight: 1,
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "10px 32px 10px",
  },
  clear: {
    color: "#111",
    cursor: "pointer",
  },
}));
const Body = (props) => {
  const getMuiTheme = () =>
    createMuiTheme((theme) => ({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: "none",
          },
        },
        MuiTableCell: {
          root: {
            padding: "6px",
          },
        },
        MuiPaper: {
          root: {
            width: "100%",
            marginTop: "20px",
          },
        },
        MuiInputBase: {
          formControl: {
            "&:hover": {
              outline: "none",
            },
          },
        },
        MuiButtonBase: {
          root: {
            backgroundColor: "rgb(63, 81, 181)",

            fontWeight: "500",
            fontSize: ".875rem",

            lineHeight: "1",
          },
        },
        MuiButton: {
          root: {
            textTransform: "capitalize",
            margin: "1rem 0",
            "&:hover": {
              backgroundColor: "rgb(63, 81, 181)",
            },
          },

          text: {
            color: "#fff",
          },
        },
      },
    }));

  const { tokenLists } = useSelector((state) => state.auth);
  const { currentPlan } = useSelector((state) => state.clientData);
  const dispatch = useDispatch();
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const classes = useStyles();

  const [copySuccess, setCopySuccess] = useState("");
  const history = useHistory();

  // const [copied, setCopied] = useState(false);
  const { openModal } = useSelector((state) => state.modalRed);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successRevoke, setSuccessRevoke] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [apiGenerated, setApiGenerated] = useState(false);
  const [error, setError] = useState(false);
  const [tokenName, setTokenName] = useState("");
  const [open, setOpen] = useState(false);

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const handleGenerate = (e) => {
    e.preventDefault();
    if (tokenName === "" || tokenName === undefined) {
      setError(true);
    } else {
      // console.log(tokenName);

      // dispatch(
      //   generateToken({
      //     token_name: tokenName
      //   })
      // )
      // setTimeout(() => {

      //   dispatch(tokenList())
      //   setApiGenerated(false)
      // }, 800)
      // props.setGenerateModal(false);

      history.push("/api-keys");
      if (openModal) {
        props.closeModal();
      }
    }

    // console.log(props.tokenList);
  };
  const handleSubscribe = () => {
    history.push("/plan");
    if (openModal) {
      props.closeModal();
    }
  };
  const handleClose = () => {
    props.closeModal();
  };

  return (
    <div style={modalStyle} className={classes.paper}>
      <Grid
        container
        justifyContent={"flex-end"}
        style={{ marginLeft: "10px" }}
      >
        <Clear className={classes.clear} onClick={handleClose} />
      </Grid>
      <Grid container justifyContent="center" alignItems={"center"}>
        {!currentPlan?.data?.plan_id && (
          <Info
            color="primary"
            className={classes.icon}
            fontSize="large"
            style={{ fontSize: "4.51875rem" }}
          />
        )}

        <Typography
          variant="h6"
          color="textPrimary"
          style={{}}
          className={
            !currentPlan?.data?.plan_id
              ? classes.unsubscribe
              : classes.subscribed
          }
        >
          {!currentPlan?.data?.plan_id
            ? `Please select a plan before proceeding further`
            : `Create new API Key`}
        </Typography>
      </Grid>
      {currentPlan?.data?.plan_id ? (
        <form>
          <Box width={"100%"} display="flex" flexDirection="column">
            {/* <InputLabel className={classes.darkText} htmlFor="Token Name">
            <br />
            {/* Authorized IPs <small>(separated by Commas ",")</small> */}
            {/* Token Name */}
            {/* </InputLabel> */}
            <InputLabel
              htmlFor="TokenName"
              style={{
                fontWeight: "500",
                color: "#000",
                padding: "0px 0px 10px 0px",
              }}
            >
              API Key
            </InputLabel>
            <TextField
              size="small"
              variant="outlined"
              id="authorizedIp"
              name="tokenName"
              value={tokenName}
              autoFocus
              error={!!error}
              onChange={(e) => {
                if (tokenName.length >= 1) {
                  setError(false);
                }
                setTokenName(e.target.value);
              }}
              helperText={error && `Please enter token name*`}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={handleGenerate}
            >
              Generate
            </Button>
          </Box>
        </form>
      ) : (
        <Button
          // type="submit"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.button}
          onClick={handleSubscribe}
        >
          Subscribe
        </Button>
      )}
    </div>
  );
};

export default Body;
