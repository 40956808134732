import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {authActions} from 'auth'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'
import Profile from 'views/pages/account/Profile'


import RequireAuthRoute from '../components/require-auth-route'
import RequireUnauthRoute from '../components/require-unauth-route'
import Sidenav from '../containers/sidenav'
import Login from '../pages/auth/Login'
import DashboardComponent from '../pages/home'

import {clientActions} from 'getDetails'
import {Link, useLocation} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import ErrorPage from 'views/pages/Error/error'
import BackgroundHexagon from 'assets/blue-pattern.svg'
import Leads from 'views/pages/account/Leads'
import DinDetail from 'views/pages/account/dinDetails'
import GSTDetail from 'views/pages/account/GSTDetails'
import FreeCredits from 'views/pages/account/freeCredits'
import {LinearProgress} from '@material-ui/core'
import LinkedinRequests from 'views/pages/account/linkedin-requests/linkedinRequests'
import Teams from 'views/pages/account/team-pages/teams'
import TeamMembers from 'views/pages/account/team-pages/teamMembers'
import TeamMemberDetails from 'views/pages/account/team-pages/teamMemberDetails'
import RechargeHistory from 'views/pages/account/recharge-history/RechargeHistory'
import LinkedinBulkRequests from 'views/pages/account/linkedin-bulk-requests/LinkedinBulkRequests'

import EmailVerified from "../pages/emailVerified/EmailVerified";
import {axiosinstance} from "../../utils/axios-instance";
// import BackgroundHexagon from "assets/medical.jpg"

//  Global material ui theme wrapper
const theme = createTheme({
    palette: {
        primary: {
            light: "#dbeffc",
            main: "#221c53",
            dark: "#0084ec",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: "#e91e63",
            dark: "#ba000d",
            contrastText: "#000",
        },
    }, // typography: {
    //   useNextVariants: true
    // }

    overrides: {
        "& .MuiModal-root": {
            filter: "blur(100px)",
        },
    },
});

const App = (props) => {
    useEffect(() => {
        setInterval(() => {
            if (props.authenticated) {
                props.refreshToken();
            }
        }, 800000);
    }, [props.authenticated]);
    // props.authenticated is true when,
    // onboardRequired is false when
    // or past login token is validated

    //  onboardRequired : i.e is new user / sign in required?
    useEffect(() => {
        if (props.modal) {
            window.addEventListener("keydown", (e) => {
                // console.log(e);
                if (e.key == "Escape") {
                    props.changeModal(false);
                }
            });
        }
    }, [props.modal]);

    useEffect(() => {
        axiosinstance.interceptors.response.use(
            (res) => {
                return res;
            },
            (err) => {
                if (
                    err.response?.data?.status_code == 401 &&
                    err.response?.data?.message_code == "token_expired"
                ) {
                    props.refreshToken();
                    return;
                }

            })
    }, [props.modal])

    useEffect(() => {
        axiosinstance.interceptors.response.use((res) => {
            return res
        }, (err) => {
            if (err.response?.data?.status_code == 401 && err.response?.data?.message_code == 'token_expired') {
                props.refreshToken();
                return;
            }
            return Promise.reject(err);
        })
    }, [axiosinstance])

    return (<MuiThemeProvider theme={theme}>
        <main
            style={{
                // backgroundImage: `url("${BackgroundHexagon}")`,
                backgroundColor: '#edf2fe', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
            }}
        >
            {(props.loader && !props.authenticated) ? <LinearProgress/> : <Sidenav authenticated={props.authenticated}>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/home'
                                  component={DashboardComponent}/>


                <RequireAuthRoute authenticated={props.authenticated} exact path='/profile'
                                  component={Profile}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/leads' component={Leads}/>

                <RequireAuthRoute authenticated={props.authenticated} exact path='/din' component={DinDetail}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/gst' component={GSTDetail}/>

                <RequireAuthRoute authenticated={props.authenticated} exact path='/email-verify'
                                  component={EmailVerified}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/teams' component={Teams}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/recharge-history'
                                  component={RechargeHistory}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/team-members/:teamID'
                                  component={TeamMembers}/>
                <RequireAuthRoute authenticated={props.authenticated} exact
                                  path='/team-member-details/:teamID/:memberID' component={TeamMemberDetails}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/free-credits'
                                  component={FreeCredits}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/linkedin-requests'
                                  component={LinkedinRequests}/>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/linkedin-bulk-requests'
                                  component={LinkedinBulkRequests}/>




                <RequireAuthRoute authenticated={props.authenticated} exact path='/account'>
                    <Redirect to='/account/profile'></Redirect>
                </RequireAuthRoute>
                <RequireAuthRoute authenticated={props.authenticated} exact path='/account/profile'
                                  component={Profile}/>

                {/* <RequireAuthRoute authenticated={props.authenticated} exact path='/account/billing' component={Billing} /> */}
                {/* <RequireAuthRoute authenticated={props.authenticated} exact path='/authentication_logs' component={AuthenticationLogs} /> */}
                {/* <RequireAuthRoute authenticated={props.authenticated} exact path='/billing' component={Billing} /> */}


                <RequireAuthRoute authenticated={props.authenticated} exact path='/document'>
                    <Redirect to='/document/create'></Redirect>
                </RequireAuthRoute>



                <RequireUnauthRoute authenticated={props.authenticated} exact path='/' component={Login}/>
                {/* <RequireUnauthRoute
            authenticated={props.authenticated}
            exact
            path="**"
            component={ErrorPage}
          /> */}
                {/* <RequireAuthRoute
            exact={true}
            path="*"
            component={ErrorPage}


          /> */}
            </Sidenav>}

        </main>
    </MuiThemeProvider>)
}

App.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    signOut: PropTypes.func.isRequired,
};

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated, // onboardRequired: state.auth.onboardRequired,
    accessToken: state.auth.accessToken,
    modal: state.modalRed.openModal,
    loader: state.auth.loader,
    checkout: state.clientData.checkout,
    planDetail: state.clientData.planDetail,
});

const mapDispatchToProps = {
    signOut: authActions.signOut,
    refreshToken: authActions.refreshToken,
    changeModal: authActions.changeModal,
    checkoutPlan: clientActions.checkOut,
    getPlanId: clientActions.getPlanID,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
