import React from "react"
import {
  Grid,
  LinearProgress,
  TextField,
  createTheme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { incrementAllottedCredits } from "getDetails/actions";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a modalLoader
import useEffectDidUpdate from "../../utils";

// opacity: 1;
// transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// position: absolute;
// left: 50%;
// top: 50%;
// transform: translate(-50%, -50%);
// width: 55rem;
// height: 28rem;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  flexDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  heading: {
    fontWeight: 600,
  },
  contentDiv: {
    margin: 20,
  },
  buttonDiv: {
    display: "flex",
    margin: "4px 0 50px 0",
    justifyContent: "center",
  },
  acceptButton: {
    color: "#fff",
    padding: "6px 6px 6px 0",
    width: "10rem",
    fontSize: "14px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
  rejectButton: {
    color: "#fff",
    padding: "6px 6px 6px 0",
    width: "10rem",
    fontSize: "14px",
    boxShadow:
      " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "red",
    "&:hover": {
      background: "#fff",
      color: "red",
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
  styleFont: {
    fontSize: 22,
    lineHeight: 1.4,
    fontWeight: 600,
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.heading}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

const getMUITheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        height: "100%",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "310px !important",
      },
      paperWidthMd: {
        width: "800px",
        height: "270px",
      },
    },
  },
});

function IncrementAllottedCreditsModal(props) {
  const { open, handleClose, classes, teamInfo, memberinfo } = props;
  const dispatch = useDispatch();

  const { statusMessage, modalLoader } = useSelector(
    (state) => state.clientData,
  );
  const [addCreditsConfirmationPage, setCreditsConfirmationPage] =
    useState(false);
  const [credits, setCredits] = useState(0);
  const [emailCredits, setEmailCredits] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCreditsConfirmationPage(true);
  };
  const handleConfirm = (event) => {
    event.preventDefault();
    setCreditsConfirmationPage(false);
    const data = {
      team_id: teamInfo.team_id,
      member_id: memberinfo.member_id,
      increment_credits_by: credits,
      increment_email_credits_by: emailCredits,
    };
    dispatch(incrementAllottedCredits(data));
  };

  useEffectDidUpdate(() => {
    if (statusMessage) {
      handleClose();
    }
  }, [statusMessage]);

  return (
    <div>
      <MuiThemeProvider theme={getMUITheme}>
        {!addCreditsConfirmationPage ? (
          <Dialog
            onClose={handleClose}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50% , -50%)",
              width: "100%",
              height: "100%",
            }}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={false}
            maxWidth={"md"}
          >
            {modalLoader && <LinearProgress />}
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Add Credits
            </DialogTitle>

            <DialogContent dividers>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Member ID: {memberinfo.member_id}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Current Credits: {memberinfo.credits_left}
                  </Typography>
                </Grid>

                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <TextField
                      onChange={(e) => setCredits(e.target.value)}
                      margin={"dense"}
                      type={"number"}
                      fullWidth={true}
                      label={"Enter Credits to Add"}
                      style={{ margin: "10px 0px" }}
                    ></TextField>
                    <TextField
                      onChange={(e) => setEmailCredits(e.target.value)}
                      type={"number"}
                      fullWidth={true}
                      label={"Enter Email Credits to Add"}
                      style={{ margin: "10px 0px" }}
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      paddingTop: "20px",
                    }}
                  >
                    <Grid item>
                      <Button
                        name="acceptButton"
                        disabled={modalLoader}
                        className={classes.acceptButton}
                        type={"submit"}
                      >
                        Accept
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              {/* <CustomSnackbar
                                display={creditsAddedStatus}
                                message={creditsAddedStatus}
                                dismissNotification={handleSnakbarClose}
                                severity={'success'}
                            /> */}
            </DialogContent>

            {/* <DialogActions>

                    </DialogActions> */}
          </Dialog>
        ) : (
          <Dialog
            onClose={handleClose}
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50% , -50%)",
              width: "100%",
              height: "100%",
            }}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={false}
            maxWidth={"md"}
          >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
              Add Credits
            </DialogTitle>

            <DialogContent
              dividers
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "center" }}>
                    {credits >= 1000 && (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "red",
                        }}
                      >
                        <InfoIcon />{" "}
                        <span>Your credits set exceed the maximum limit,</span>
                      </Typography>
                    )}
                    Do you really want to add {credits} credits and{" "}
                    {emailCredits} email credits in {memberinfo.member_id}{" "}
                    account??
                  </Typography>
                </Grid>
                <br />
                <Grid
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    paddingTop: "20px",
                  }}
                >
                  <Grid item>
                    <Button
                      disabled={modalLoader}
                      className={classes.rejectButton}
                      onClick={handleConfirm}
                    >
                      <Typography>Yes</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={modalLoader}
                      className={classes.acceptButton}
                      onClick={() => {
                        setCreditsConfirmationPage(false);
                      }}
                    >
                      <Typography>No</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
      </MuiThemeProvider>
    </div>
  );
}

IncrementAllottedCreditsModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncrementAllottedCreditsModal);
