export const CLIENTS_LIST = 'CLIENTS_LIST'
export const CLIENT_DETAILS = 'CLIENT_DETAILS'
export const PLANDETAIL = 'PLANDETAIL'
export const PLANDETAIL_FAILURE = 'PLANDETAIL_FAILURE'
export const CHECKOUT_DETAIL = 'CHECKOUT_DETAIL'
export const CHECKOUT_DETAIL_FAILURE = 'CHECKOUT_DETAIL_FAILURE'
export const INITIAL_CHECKOUT = 'INITIAL_CHECKOUT'
export const PLAN_ID = 'PLAN_ID'
export const BILLING_DETAIL = 'BILLING_DETAIL'

export const AUTH_LOG = 'AUTH_LOG'
export const AUTH_LOG_FAILURE = 'AUTH_LOG_FAILURE'
export const BILLING_DETAIL_FAILURE = 'BILLING_DETAIL_FAILURE'
export const PROFILE_DETAIL = 'PROFILE_DETAIL'
export const PROFILE_DETAIL_FAILURE = 'PROFILE_DETAIL_FAILURE'
export const CURRENT_PLAN = 'CURRENT_PLAN'
export const CURRENT_PLAN_FAILURE = 'CURRENT_PLAN_FAILURE'
export const API_USAGE = 'API_USAGE'
export const API_USAGE_FAILURE = 'API_USAGE_FAILURE'
export const LEADS_EXTRACTED = 'LEADS_EXTRACTED'
export const LEADS_EXTRACTED_FAILURE = 'LEADS_EXTRACTED_FAILURE'
export const GET_TOTAL_CREDITS = 'GET_TOTAL_CREDITS'
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const FIRST_POPUP = 'FIRST_POPUP'

export const REMOVE_CANCEL_SCHEDULE = 'REMOVE_CANCE_SCHEDULE'
export const REMOVE_CANCEL_SCHEDULE_FAILURE = 'REMOVE_CANCE_SCHEDULE_FAILURE'

export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_PLAN_FAILURE = 'CHANGE_PLAN_FAILURE'

export const CANCEL_CHANGE_PLAN = 'CANCEL_CHANGE_PLAN'
export const CANCEL_CHANGE_PLAN_FAILURE = 'CANCEL_CHANGE_PLAN_FAILURE'

export const ESTIMATE_REQ_COST_PRICE = "ESTIMATE_REQ_COST_PRICE"
export const ESTIMATE_REQ_COST_PRICE_FAILURE = "ESTIMATE_REQ_COST_PRICE_FAILURE"

export const SET_RESPONSE = "SET_RESPONSE"

export const DELETE_DATA = 'DELETE_DATA'

export const GET_DIN_EMAIL_DATA = 'GET_DIN_EMAIL_DATA'
export const GET_DIN_EMAIL_DATA_FAILURE = 'GET_DIN_EMAIL_DATA_FAILURE'

export const GET_DIN_PHONE_DATA = 'GET_DIN_PHONE_DATA'
export const GET_DIN_PHONE_DATA_FAILURE = 'GET_DIN_PHONE_DATA_FAILURE'

export const GET_EMAIL_PHONE = 'GET_EMAIL_PHONE'
export const GET_EMAIL_PHONE_GST = 'GET_EMAIL_PHONE_GST'
export const GET_EMAIL_PHONE_FAILURE_GST = 'GET_EMAIL_PHONE_FAILURE_GST'
export const GET_EMAIL_PHONE_FAILURE = 'GET_EMAIL_PHONE_FAILURE'

export const LINKEDIN_REQUESTS = 'LINKEDIN_REQUESTS'
export const LINKEDIN_REQUESTS_FAILURE = 'LINKEDIN_REQUESTS_FAILURE'

export const GET_LINKEDIN_BULK_REQUESTS = "GET_LINKEDIN_BULK_REQUESTS"
export const GET_LINKEDIN_BULK_REQUESTS_FAILURE = "GET_LINKEDIN_BULK_REQUESTS_FAILURE"

export const GET_RESOLVED_LINKEDIN_BULK_REQUEST = "GET_RESOLVED_LINKEDIN_BULK_REQUEST"
export const GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE = "GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE"

export const SUBMIT_LINKEDIN_BULK_REQUEST = "SUBMIT_LINKEDIN_BULK_REQUEST"
export const SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE = "SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE"


export const GET_TEAM = "GET_TEAM"
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE"

export const GET_ALL_TEAMS = "GET_ALL_TEAMS"
export const GET_ALL_TEAMS_FAILURE = "GET_ALL_TEAMS_FAILURE"

export const CREATE_TEAM = "CREATE_TEAM"
export const CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE"

export const DELETE_TEAM = "DELETE_TEAM"
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE"

export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER"
export const ADD_TEAM_MEMBER_FAILURE = "ADD_TEAM_MEMBER_FAILURE"

export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER"
export const REMOVE_TEAM_MEMBER_FAILURE = "REMOVE_TEAM_MEMBER_FAILURE"

export const GET_TEAM_MEMBER_DETAILS = "GET_TEAM_MEMBER_DETAILS"
export const GET_TEAM_MEMBER_DETAILS_FAILURE = "GET_TEAM_MEMBER_DETAILS_FAILURE"

export const INCREMENT_ALLOTTED_CREDITS = "INCREMENT_ALLOTTED_CREDITS"
export const INCREMENT_ALLOTTED_CREDITS_FAILURE = "INCREMENT_ALLOTTED_CREDITS_FAILURE"

export const SET_MODAL_LOADER = "SET_MODAL_LOADER"
export const DETAILS_LOADER = "DETAILS_LOADER"

export const REPORT_LEAD_EXTRACTED = "REPORT_LEAD_EXTRACTED"
export const REPORT_LEAD_EXTRACTED_FAILURE = "REPORT_LEAD_EXTRACTED_FAILURE"

export const REPORT_LINKEDIN_PROFILE = "REPORT_LINKEDIN_PROFILE"
export const REPORT_LINKEDIN_PROFILE_FAILURE = "REPORT_LINKEDIN_PROFILE_FAILURE"

export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE"

export const GET_RECHARGE_HISTORY = "GET_RECHARGE_HISTORY"
export const GET_RECHARGE_HISTORY_FAILURE = "GET_RECHARGE_HISTORY_FAILURE"

export const GET_USER_TEAMS_DETAILS = "GET_USER_TEAMS_DETAILS"
export const GET_USER_TEAMS_DETAILS_FAILURE = "GET_USER_TEAMS_DETAILS_FAILURE"

export const SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE"

export const CLEAR_ERROR = 'CLEAR_ERROR'